import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import {
  Box, 
  Grid, 
  Typography,
  CircularProgress
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faAngleDoubleUp,
  faAngleDoubleDown,
  faMinus
} from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles((theme) => ({
  iconUp:{
    color: theme.palette.success.light
  },
  iconDown:{
    color: theme.palette.error.light
  },
  default:{}
}));
export default function Rating(props) {
  const classes = useStyles();

  var icon = faMinus;
  var style = classes.default;

  if(props.diff < 0){
    icon = faAngleDoubleDown
    style = classes.iconDown;
  }
  else if(props.diff > 0){
    icon = faAngleDoubleUp;
    style = classes.iconUp;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography component="div">
          <Box textAlign="center" fontWeight="fontWeightBold">
            {Math.abs(props.diff)} <FontAwesomeIcon icon={icon} className={style}/>
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
      <Typography component="div" variant="caption">
          <Box textAlign="center">
            {props.text}
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
}