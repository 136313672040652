import AppConfig from './config'
import {initRollout} from './Flags'

import {Logger, LogLevel} from './services/logging/logger'

import {SutwokuApiClient} from './services/clients/sutwoku-api-client'

import {ContentCache} from './services/content-cache/content-cache'

import {LocalStorage} from './services/storage/local-storage'

//Logging
import {ApplicationInsights } from '@microsoft/applicationinsights-web'

//Bot
import {Bot} from './services/bot/bot'

//Match Making
import {MatchMakingService} from './services/match-making/match-making-service'
import {MatchMakingMessageFactory} from './services/match-making/match-making-message-factory'
import {MatchMakingMessageHandlerFactory} from './services/match-making/match-making-handler-factory'

//Match Making Message Handlers
import {DefaultMatchMakingMessageHandler} from './services/match-making/handlers/default-handler'
import {FriendWaitingMessageHandler} from './services/match-making/handlers/friend-waiting-message-handler'
import {GameFoundMessageHandler} from './services/match-making/handlers/game-found-message-handler'
import {FriendRejectedGameMessageHandler} from './services/match-making/handlers/friend-rejected-game-message-handler'

//Messaging
import {SocketService} from './services/socket/socket-service'
import {MessageManager} from './services/messaging/message-manager'
import {MessageFactory} from './services/messaging/message-factory'
import {MessageHandlerFactory} from './services/messaging/message-handler-factory'

//MessageHandlers
import {DefaultMessageHandler} from './services/messaging/message-handlers/default-handler'
import {OpponentFoundMessageHandler} from './services/messaging/message-handlers/opponent-found-message-handler'
import {StartGameMessageHandler} from './services/messaging/message-handlers/start-game-handler'
import {GuessResultHandler} from './services/messaging/message-handlers/guess-result-handler';
import {ScoreUpdateHandler} from './services/messaging/message-handlers/score-update-handler';
import {ConnectionSuccessMessageHandler} from './services/messaging/message-handlers/connection-success-message-handler';
import { FinishGameMessageHandler } from './services/messaging/message-handlers/finish-game-handler';
import { BotSettingMessageHandler } from './services/messaging/message-handlers/bot-settings-message-handler';
import { DisconnectionMessageHandler } from './services/messaging/message-handlers/disconnection-message-handler';
import { ReconnectionSuccessMessageHandler } from './services/messaging/message-handlers/reconnection-message-handler';


export const createServices = () => {
  const appInsights = new ApplicationInsights({ config: {
    instrumentationKey: AppConfig.aiKey
  } });
  appInsights.loadAppInsights();
 
  var services = {};

  services.logger = new Logger(appInsights);
  services.logger.logMessage(LogLevel.Info, `New Game Session -> IsWebVersion:${AppConfig.isWebVersion}`)

  // https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
  window.onerror = function (msg, url, lineNo, columnNo, error) {
    var message = [
      'Message: ' + msg,
      'URL: ' + url,
      'Line: ' + lineNo,
      'Column: ' + columnNo,
      'Error object: ' + JSON.stringify(error)
  ].join(',');
    services.logger.logMessage(LogLevel.Error, message);
  }


  initRollout(services.logger);

  //clients
  services.apiClient = new SutwokuApiClient(services.logger, AppConfig);
  services.contentCache = new ContentCache(services.logger, AppConfig);
  services.contentCache.init();

  //repos
  services.storage = new LocalStorage();

  //messaging
  var messageHandlerFactory = new MessageHandlerFactory(
    new DefaultMessageHandler(new Logger(appInsights)),
      new OpponentFoundMessageHandler(new Logger(appInsights), services.storage),
      new StartGameMessageHandler(new Logger(appInsights), services.storage),
      new GuessResultHandler(new Logger(appInsights)),
      new ScoreUpdateHandler(new Logger(appInsights)),
      new ConnectionSuccessMessageHandler(new Logger(appInsights)),
      new FinishGameMessageHandler(new Logger(appInsights)),
      new BotSettingMessageHandler(new Logger(appInsights), services),
      new DisconnectionMessageHandler(new Logger(appInsights)),
      new ReconnectionSuccessMessageHandler(new Logger(appInsights)));
      

  services.messageManager = new MessageManager(new MessageFactory(), 
    new SocketService(AppConfig.gameWsBaseUrl + 'game', new Logger(appInsights)),
    new Logger(appInsights),
    messageHandlerFactory
  );

  var matchMakingMessageHandlerFactory = new MatchMakingMessageHandlerFactory(
    new DefaultMatchMakingMessageHandler(new Logger(appInsights)),
    new FriendWaitingMessageHandler(new Logger(appInsights)),
    new GameFoundMessageHandler(new Logger(appInsights), services),
    new FriendRejectedGameMessageHandler(new Logger(appInsights))
  )

  services.matchMakingService = new MatchMakingService(
    new SocketService(AppConfig.matchMakerWsBaseUrl + 'match-maker', new Logger(appInsights)),
    new MatchMakingMessageFactory(),
    matchMakingMessageHandlerFactory
  );

  services.bot = new Bot(services.apiClient, services.messageManager);

  return services;
}