export const SET_SETTINGS = 'SET_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';

import {LogLevel} from './../services/logging/logger'

export const loadSettings = () => {
  return(dispatch, getState, services) => {
    return services.storage.get('settings')
      .then(settings => {
        dispatch(setSettings(settings ?? {}))
      })
  };
};

export const toggleThemeMode = () => {
  return(dispatch, getState, services) => {
    var currentSettings = getState().settings;
    currentSettings.themeType = currentSettings.themeType === 'light' ? 'dark' : 'light'
    services.logger.logMessage(LogLevel.Info, `Toggling Theme : ${currentSettings.themeType}`)
    dispatch(setSettings(currentSettings))
    return services.storage.save('settings', currentSettings)
  };
};

export const toggleNoises = () => {
  return(dispatch, getState, services) => {
    var currentSettings = getState().settings;
    currentSettings.noises = !currentSettings.noises 
    dispatch(setSettings(currentSettings))
    services.logger.logMessage(LogLevel.Info, `Toggling Noises : ${currentSettings.noises}`)
    return services.storage.save('settings', currentSettings)
  };
};

export const setSettings = (settings) => {
  return {
    type: SET_SETTINGS,
    settings
  }
};