import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Player from './player'

const useStyles = makeStyles((theme) => ({
}));

export default function Scoreboard(props) {
  const classes = useStyles();
  return (
    <Grid container spacing={2} mx={1}>
      <Grid item xs={6}>
        <Player score={props.scores.yourScore} details={props.scores.playerDetails} player/>
      </Grid>
      <Grid item xs={6}>
        <Player score={props.scores.theirScore} details={props.scores.theirDetails}/>
      </Grid>
    </Grid>
  );
}