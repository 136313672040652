import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Grid,
  CardContent,
  Box,
  Typography,
  CircularProgress
} from '@material-ui/core';

import RatingBox from './rating-box'

const useStyles = makeStyles((theme) => ({
  cardContent:{
    padding:theme.spacing(1),
    '&:last-child': {
      paddingBottom:theme.spacing(1),
    }
  }
}));
export default function Rating(props) {
  const classes = useStyles();

  var currentRaking;
  var diffToday = 0;
  var diffWeek = 0;
  var diffMonth = 0;

  if(props.stats && 
      props.stats.rankingCountStats){
        currentRaking = props.stats.rankingCountStats.currentRating;

        if(props.stats.rankingStats){
            diffToday = currentRaking - props.stats.rankingStats[1]
            diffWeek = currentRaking - props.stats.rankingStats[7]
            diffMonth = currentRaking - props.stats.rankingStats[29]
        }
  }

  return (
    <Card>
      <CardContent className={classes.cardContent} >
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Typography component="div" variant="subtitle1" fontWeight="fontWeightBold">
              <Box textAlign="center" >
                RATING
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="div" variant="h3">
              <Box textAlign="center" fontWeight="fontWeightBold">
              {currentRaking &&
                currentRaking
              }
              {(!currentRaking && currentRaking != 0) &&
                <CircularProgress size='0.875rem' color='inherit'/>
              }
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <RatingBox text='Last Day' diff={diffToday}/>
          </Grid>
          <Grid item xs={4}>
            <RatingBox text='Last Week' diff={diffWeek}/>
          </Grid>
          <Grid item xs={4}>
            <RatingBox text='Last Month' diff={diffMonth}/>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}