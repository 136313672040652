import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Box,
  CircularProgress,
  Typography,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card:{
    padding:theme.spacing(1)
  }
}));

const calcuateAverageScore = (stats) => {
  var total = 0;
  var countPreset = 0;

  if(stats.averageWinningScore > 0){
    total = total + stats.averageWinningScore;
    countPreset++;
  }

  if(stats.averageDrawScore > 0){
    total = total + stats.averageDrawScore;
    countPreset++;
  }

  if(stats.averageLosingScore > 0){
    total = total + stats.averageLosingScore;
    countPreset++;
  }

  if(countPreset === 0){
    return 0;
  }

  return Math.round(total/countPreset);
}

export default function AverageScore(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Grid container>
      <Grid item xs={12}>
          <Typography component="div" variant="caption">
              <Box textAlign="center">
              AVERAGE SCORE
              </Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="div" variant="h4">
            <Box textAlign="center" fontWeight="fontWeightBold">
            {props.stats &&
              calcuateAverageScore(props.stats)
            }
            {(!props.stats) &&
              <CircularProgress size='0.875rem' color='inherit'/>
            }
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}