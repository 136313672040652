import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Grid,
  CardContent,
  Typography,
  Box,
  CircularProgress
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardContent:{
    padding:theme.spacing(1),
    '&:last-child': {
      paddingBottom:theme.spacing(1),
    }
  },
  default:{
    padding:theme.spacing(1),
    color:theme.palette.secondary.contrastText,
    backgroundColor:theme.palette.grey[500],
    borderRadius:theme.shape.borderRadius
  },
  win:{
    padding:theme.spacing(1),
    color:theme.palette.success.contrastText,
    backgroundColor:theme.palette.success.main,
    borderTopLeftRadius:theme.shape.borderRadius,
    borderBottomLeftRadius:theme.shape.borderRadius
  },
  draw:{
    padding:theme.spacing(1),
    color:theme.palette.warning.contrastText,
    backgroundColor:theme.palette.warning.light
  },
  loss:{
    padding:theme.spacing(1),
    color:theme.palette.error.contrastText,
    backgroundColor:theme.palette.error.main,
    borderTopRightRadius:theme.shape.borderRadius,
    borderBottomRightRadius:theme.shape.borderRadius
  }
}));

const calculatePercentages = (totalCount, resultCount) => {
  const minWidth=10;
  const maxWidth=80;

  if(!resultCount)
    return minWidth;

  var percentage = (resultCount * 100.00)/totalCount;

  if(percentage <= minWidth){
    return minWidth;
  }
  if(percentage >= maxWidth){
    return maxWidth;
  }

  return percentage;
}


export default function GameRecord(props) {
  const classes = useStyles();
  
  var percentages = {
    win:0,
    loss:0,
    draw:0
  }

  if(props.stats){
    percentages.win=calculatePercentages(props.stats.noOfGames, props.stats.noOfWins);
    percentages.draw=calculatePercentages(props.stats.noOfGames, props.stats.noOfDraws);
    percentages.loss=calculatePercentages(props.stats.noOfGames, props.stats.noOfLosses);
  }

  return (
    <Card>
      <CardContent className={classes.cardContent} >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="div" variant="subtitle1" fontWeight="fontWeightBold">
              <Box textAlign="center" >
                GAME RECORD
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box width='100%' textAlign='center'>
                  {!props.stats &&
                    <Box width='100%' className={classes.default}>
                      <CircularProgress size='0.875rem' color='inherit'/>
                    </Box>
                  }
                  {(props.stats && props.stats.noOfGames === 0) &&
                    <Box width='100%' className={classes.default}>
                      -
                    </Box>
                  }
                  {(props.stats && props.stats.noOfGames > 0) &&
                    <Box width='100%' display='flex' >
                      <Box width={`${percentages.win}%`} className={classes.win} >
                        {props.stats.noOfWins}
                      </Box>
                      <Box width={`${percentages.draw}%`} className={classes.draw}>
                        {props.stats.noOfDraws}
                      </Box>
                      <Box width={`${percentages.loss}%`} className={classes.loss}>
                        {props.stats.noOfLosses}
                      </Box>
                    </Box>
                  }
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}