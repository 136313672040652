import { connect } from 'react-redux'
import GameComponent from './../components/game/game'

import {getAccount} from './../actions/account'
import {doButtonClick, doTileClick, resetControls} from '../actions/control'
import {connectToServer, cleanUpGame, disconnectFromServer} from '../actions/game'
import {toggleThemeMode} from './../actions/settings'


const mapStateToProps = state => {
  return {
    account:state.account,
    puzzle:state.puzzle,
    game:state.game
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    cleanUpGame:() => dispatch(cleanUpGame()),
    getAccount:() => dispatch(getAccount()),
    doTileClick:(col, row) => dispatch(doTileClick(col, row)),
    connectToServer:(account, gameType) => dispatch(connectToServer(account, gameType)),
    disconnectFromServer:() => dispatch(disconnectFromServer()),
  }
}
 
const GameContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GameComponent)
 
export default GameContainer