import { connect } from 'react-redux'
import App from './../components/app'


const mapStateToProps = state => {
  return {
    theme:state.settings.themeType,
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}
 
const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
 
export default AppContainer