export const SET_PUZZLE = 'SET_PUZZLE';
export const SET_PUZZLE_FOCUS = 'SET_PUZZLE_FOCUS';
export const REMOVE_PUZZLE_FOCUS = 'REMOVE_PUZZLE_FOCUS';
export const SET_CORRECT_TILE = 'SET_CORRECT_TILE';
export const CLEAR_PUZZLE = 'CLEAR_PUZZLE';

export const setPuzzle = (puzzle) => {
  return {
    type: SET_PUZZLE,
    puzzle: puzzle
  }
};

export const setCorrectTile = (col, row, value, playerNo) => {
  return {
    type: SET_CORRECT_TILE,
    col,
    row,
    value,
    playerNo
  }
};

export const setPuzzleFocus = (col, row, playerNo) => {
  return {
    type: SET_PUZZLE_FOCUS,
    col,
    row,
    playerNo
  }
};

export const removePuzzleFocus = () => {
  return {
    type: REMOVE_PUZZLE_FOCUS
  }
};

export const clearPuzzle = () => {
  return {
    type: CLEAR_PUZZLE
  }
};