import {setPuzzleFocus, removePuzzleFocus} from './puzzle'

export const SET_TILE_FOCUS = 'SET_TILE_FOCUS';
export const SET_BUTTON_FOCUS = 'SET_BUTTON_FOCUS';
export const SET_CONTROL_STATUS = 'SET_CONTROL_STATUS';
export const REMOVE_TILE_FOCUS = 'REMOVE_TILE_FOCUS';
export const REMOVE_FOCUS = 'REMOVE_FOCUS';

export const doTileClick = (col, row) => {
  return(dispatch, getState) => {
    var state = getState();

    if(state.control.status === 'GUESSING' || 
        state.puzzle[row][col].value != 0)
      return Promise.resolve();

    if(!state.control.buttonFocus){
      //No buttonFocus set so lets set tile it
      dispatch(setPuzzleFocus(col, row, 1));
      dispatch(setTileFocus(col, row));
      if(state.control.tileFocus){
        //Focus is already set so we need to remove this
        if(state.puzzle[state.control.tileFocus.row]
              [state.control.tileFocus.col].value === 0)
          dispatch(setPuzzleFocus(state.control.tileFocus.col, state.control.tileFocus.row))
      }
    }else{
      //button focus is set so need to doGuess
      dispatch(doGuess(col, row, state.control.buttonFocus.number));
    }
    

    return Promise.resolve();
  };
};

export const doButtonClick = (number) => {
  return(dispatch, getState) => {
    var state = getState();

    if(state.control.status === 'GUESSING')
      return Promise.resolve();

    if(state.control.tileFocus){
      //we need to do a guess here
      if(state.puzzle[state.control.tileFocus.row]
            [state.control.tileFocus.col].value === 0)
        dispatch(doGuess(state.control.tileFocus.col, state.control.tileFocus.row, number));
    }
    else{
      if(state.control.buttonFocus &&
          state.control.buttonFocus.number === number)
            dispatch(removeFocus())
      else
        dispatch(setButtonFocus(number));
    }

    return Promise.resolve();
  };
};

export const doGuess = (col, row, number) => {
  return(dispatch, getState, services) => {
    var state = getState();

    if(state.control.status === 'GUESSING')
      return Promise.resolve();

    dispatch(setControlStatus('GUESSING'));

    services.messageManager.sendGuessMessage(state.game.gameRef, col, row, number)
      .then(() => {
        return Promise.resolve();
      });
  };
};

export const setControlStatus = (status) => {
  return {
    type:SET_CONTROL_STATUS,
    status
  };
};

export const setTileFocus = (col, row) => {
  return {
    type:SET_TILE_FOCUS,
    col,
    row
  };
};

export const setButtonFocus = (number) => {
  return {
    type:SET_BUTTON_FOCUS,
    number
  };
};

export const resetControls = () => {
  return(dispatch, getState, services) => {
    dispatch(setControlStatus('IDLE'));
    dispatch(removeFocus());
    dispatch(removePuzzleFocus());
  };
};

export const removeFocus = () => {
  return {
    type:REMOVE_FOCUS
  };
};