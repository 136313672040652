import {SET_PUZZLE, SET_PUZZLE_FOCUS, REMOVE_PUZZLE_FOCUS, SET_CORRECT_TILE, CLEAR_PUZZLE} from './../actions/puzzle'
import {tileFactory} from './../services/factories/tile-factory'

var setStartPuzzle = (startPuzzle) => {
  var puzzle = []

  for(var i = 0;i<9;i++){
    var row = []
    for(var j = 0;j<9;j++){
      var value = 0;

      if(startPuzzle != null)
        value = startPuzzle[i][j];

      var tile = tileFactory.createTile(value);
      tile.col = j;
      tile.row = i;
      row.push(tile);
    }
    puzzle.push(row);
  }

  return puzzle;
}

var removePuzzleFocus = (puzzle) => {
  var updatedPuzzle = []

  for(var i = 0;i<9;i++){
    var row = []
    for(var j = 0;j<9;j++){
      var tile = puzzle[i][j];

      if(tile.style === 'focus'){
        var tile = tileFactory.createTile(0);
        tile.col = j;
        tile.row = i;
      }
       
      row.push(tile);
    }
    updatedPuzzle.push(row);
  }

  return updatedPuzzle;
}

var updateObjectInRow = (row, updateIndex, updateObject) => {
  return row.map( (item, index) => {
    if(index === updateIndex) 
      return {
        col:item.col,
        row:item.row,
        style:updateObject.style,
        value:updateObject.value
      };  
 
    return item;
  });
}

var updateRow = (array, updateIndex, updatedRow) => {
  return array.map( (row, index) => {
    if(index === updateIndex) 
      return updatedRow;
 
    return row;
  });
}

const puzzle = (state, action) => {
  if(!state)
    state = setStartPuzzle();

  switch(action.type){
    case CLEAR_PUZZLE:
      var newState =setStartPuzzle()
    case SET_PUZZLE:
      var newState = setStartPuzzle(action.puzzle);
      return newState;
    case SET_PUZZLE_FOCUS:
      var newTile = tileFactory.createTile(0, action.playerNo);
      var row = state[action.row];
      var newRow = updateObjectInRow(row, action.col, newTile);
      var newState = updateRow(state, action.row, newRow);
      return newState;
    case REMOVE_PUZZLE_FOCUS:
      var newPuzzle = removePuzzleFocus(state);
      return newPuzzle;
    case SET_CORRECT_TILE:
      var newTile = tileFactory.createTile(action.value, action.playerNo, true);
      var row = state[action.row];
      var newRow = updateObjectInRow(row, action.col, newTile);
      var newState = updateRow(state, action.row, newRow);
      return newState;
    default:
      return state;
  }
};

export default puzzle;