import { createMuiTheme, ThemeProvider, responsiveFontSizes} from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import React from "react";
import { makeStyles } from '@material-ui/core/styles';

import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/pink';

const useStyles = makeStyles((theme) => ({
  root: {
    width:'100%',
    padding:'env(safe-area-inset-top,0) env(safe-area-inset-right,0) env(safe-area-inset-bottom,0) env(safe-area-inset-left,0)'
  },
  container: {
    height:'100vh',
    maxWidth: '56vh',
    margin:'auto',
  }
}));

export default function App(props) {
  const classes = useStyles(); 

  var theme = createMuiTheme({
    palette: {
      primary: blue,
      secondary: pink,
      type: props.theme,
    }
  });

  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <div className={classes.container}>
          {props.children}
        </div>
      </div>
    </ThemeProvider>
  );
}
