import {LogLevel} from './../../logging/logger'
import {handleDisconnectionSuccess} from './../../../actions/game';

export class DisconnectionMessageHandler {
  constructor(logger) {
    this.logger = logger;
  }
  handleMessage(message, dispatch){
    this.logger.logMessage(LogLevel.Debug, 'Handling disconnection message, Message : ' + JSON.stringify(message));
    dispatch(handleDisconnectionSuccess());
    return Promise.resolve();
  }
}

