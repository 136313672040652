import {LogLevel} from './../../logging/logger'
import {finishGame} from './../../../actions/game';

export class FinishGameMessageHandler {
  constructor(logger) {
    this.logger = logger;
  }
  handleMessage(message, dispatch){
    this.logger.logMessage(LogLevel.Debug, 'Finishing Game via FinishGameMessageHandler, Message : ' + JSON.stringify(message));
    dispatch(finishGame())
    return Promise.resolve();
  }
}
