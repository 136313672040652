import React from 'react';

import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons'

import {unregister} from '../../../actions/account'


const useStyles = makeStyles((theme) => ({
  menuButton:{
    position: "fixed",
    top: theme.spacing(1),
    padding:'env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0px) env(safe-area-inset-left, 0px)',
    right: theme.spacing(1)
  },
  menuItem:{
    margin:'auto'
  },
  menuItemIcon:{
    marginRight:'10px'
  }
}));

function SutwokuMenuSignOut(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const unregister = () => {
    props.unregister()
      .then(() => {
        setOpen(false);
        props.beginExit('/')
      })
  };

  return (
    <Box>
        <MenuItem onClick={() => {setOpen(true);}} className={classes.menuItem} >
          <FontAwesomeIcon icon={faSignOutAlt} className={classes.menuItemIcon}/> Log Out
        </MenuItem>
        <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.isGuest &&
              <span>As this is a guest account your game history will be lost forever! Do you still want to log out</span>
            }
            {!props.isGuest &&
              <span>You will be able to sign in again using your username and password if you still remember them!</span>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setOpen(false);}} color="primary" variant="contained" size="small" autoFocus>
            Cancel
          </Button>
          <Button onClick={unregister} color="secondary" size="small">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    isGuest:state.account.guest === true
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    unregister:() => dispatch(unregister()),
  }
}
 
const SutwokuMenuSignOutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SutwokuMenuSignOut)
 
export default SutwokuMenuSignOutContainer
