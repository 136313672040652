
import {LogLevel} from './../services/logging/logger'

export const SET_MATCH_MAKER_STATUS = 'SET_MATCH_MAKER_STATUS'
export const FRIEND_WAITING = 'FRIEND_WAITING'
export const REJECT_FRIEND_WAITING = 'REJECT_FRIEND_WAITING'
export const ACCEPT_FRIEND_GAME = 'ACCEPT_FRIEND_GAME'
export const GAME_FOUND = 'GAME_FOUND'
export const INIT_STATE = 'INIT_STATE'
export const LEFT_QUEUE = 'LEFT_QUEUE'
export const FRIEND_REJECTED_GAME = 'FRIEND_REJECTED_GAME'
export const DISMISS_FRIEND_REJECTED_GAME = 'DISMISS_FRIEND_REJECTED_GAME'
export const SET_GAME_LEVEL = 'SET_GAME_LEVEL'
export const SET_FRIEND_GAME_STATS= 'SET_FRIEND_GAME_STATS'

const BOT_WAIT_TIME = 3;

export const logAccordianSelection = (accordianType) => {
  return(dispatch, getState, services) => {
    services.logger.logMessage(LogLevel.Debug, `Opening Match Maker Accordian -> ${accordianType}`);
  };
}

export const connectToServer = () => {
  return(dispatch, getState, services) => {
    return services.matchMakingService.openSocket(dispatch)
      .then(() => {
        dispatch(setStatus('WAITING'))
        return services.matchMakingService.sendConnectionMessage(getState().account)
      });
  };
};

export const disconnectFromServer = () => {
  return(dispatch, getState, services) => {
    return services.matchMakingService.disconnect();
  };
};

export const leaveQueue = () => {
  return(dispatch, getState, services) => {
    services.logger.logMessage(LogLevel.Debug, 'Leaving Queue');
    dispatch(leftQueue())
    return services.matchMakingService.sendLeaveQueueMessage();
  };
};

export const initFriendGame = (friendName) => {
  return(dispatch, getState, services) => {
    var gameLevel = getState().matchMaker.gameLevel;
    return services.matchMakingService.sendFriendSelectionMessage(friendName, gameLevel)
    .then(() => {
      dispatch(setStatus('WAITING_FOR_FRIEND'))
    })
  };
};

export const changeGameLevel = (gameLevel) => {
  return(dispatch, getState, services) => {
    services.logger.logMessage(LogLevel.Debug, `Match Maker : Change Difficulty -> ${gameLevel}`);
    dispatch(setGameLevel(gameLevel))
  };
};

export const acceptFriendWaiting = () => {
  return(dispatch, getState, services) => {
    dispatch(acceptFriendGame())
    return services.matchMakingService.sendAcceptFriendGameMessage()
  };
};

export const joinRandomGame = (gameType) => {
  return(dispatch, getState, services) => {
    dispatch(setStatus('WAITING_FOR_OPPONENT'))
    var gameLevel = getState().matchMaker.gameLevel;
    return services.matchMakingService.sendJoinRandomGameMessage(gameType, gameLevel)
      .then(() => {
        setTimeout(() => {
          if(getState().matchMaker.status == "WAITING_FOR_OPPONENT"){
            services.logger.logMessage(LogLevel.Debug, 'No player found so initiating bot');
            services.matchMakingService.sendInitBotMessage(gameType, gameLevel)
              .then(() => {
                return Promise.resolve();
              });
          }
          }, BOT_WAIT_TIME * 1000);
      })
  };
};

export const rejectFriendWaiting = () => {
  return(dispatch, getState, services) => {
    dispatch(rejectFriendGame())
    return services.matchMakingService.sendRejectFriendGameMessage()
  };
};

export const handleGameFound = (gameRef, gameType, gameLevel, isBotGame) => {
  return(dispatch, getState, services) => {
    services.storage.save("game", {gameRef, gameType, gameLevel, isBotGame});
    services.matchMakingService.disconnect();
    dispatch(gameFound(gameRef, gameType, gameLevel))
  };
}

export const getFriendGameStats = () => {
  return(dispatch, getState, services) => {
    services.apiClient.getFriendGameStats(getState().account)
      .then(stats => {
        dispatch(setFriendGameStats(stats))
      });
  };
}


export const friendWaiting = (friendName) => {
  return {
    type: FRIEND_WAITING,
    friendName
  }
};

export const acceptFriendGame = () => {
  return {
    type: ACCEPT_FRIEND_GAME,
  }
};

export const rejectFriendGame = () => {
  return {
    type: REJECT_FRIEND_WAITING,
  }
};

export const gameFound = (gameRef, gameType, gameLevel) => {
  return {
    type: GAME_FOUND,
    gameRef,
    gameType, 
    gameLevel
  }
};

export const friendRejectedGame = (friendName) => {
  return {
    type: FRIEND_REJECTED_GAME,
    friendName,
  }
};

export const setFriendGameStats = (stats) => {
  return {
    type: SET_FRIEND_GAME_STATS,
    stats,
  }
};

export const dismissFriendRejectedGame = () => {
  return {
    type: DISMISS_FRIEND_REJECTED_GAME,
  }
};

export const setStatus = (status) => {
  return {
    type: SET_MATCH_MAKER_STATUS,
    status
  }
};

export const initState = () => {
  return {
    type: INIT_STATE
  }
};

export const leftQueue = () => {
  return {
    type: LEFT_QUEUE
  }
};

export const setGameLevel = (gameLevel) => {
  return {
    type: SET_GAME_LEVEL,
    gameLevel
  }
};