import React from "react";

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { makeStyles } from '@material-ui/core/styles';
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backdropFilter: 'blur(4px)'
  },
  paper:{
    width:'60%'
  },
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  warningIcon:{
    color:theme.palette.error.main
  }
}));

export default function Loading(props) {
  const classes = useStyles();

  return (
      <Backdrop className={classes.backdrop} open={props.show}>
        <Paper className={classes.paper} >
          <Box m={2} w='100%'>
            {!props.error &&
              <Box display="flex" marginTop={3} marginBottom={1} justifyContent="center">
                <div className={classes.progress}>
                  <LinearProgress color="secondary" />
                </div>
              </Box>
            }
            {props.error &&
              <Box display="flex" justifyContent="center" >
                <FontAwesomeIcon className={classes.warningIcon} size="4x" icon={faTimesCircle}/>
              </Box>
            }
            <Box display="flex" justifyContent="center">
              <Typography component="div">
                <Box m={1} textAlign='center'>
                  {props.text}
                </Box>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Backdrop>
  );
}