import {LogLevel} from '../logging/logger';

const generateUrl = (appConfig, resourceId) => {
  return appConfig.apiBaseUrl + resourceId;
}

const doRequest = (httpMethod, entity, url, logger, resolve, reject, token, timeout = 2000) => {
  let xhr = new XMLHttpRequest();
  xhr.open(httpMethod, url);
  xhr.timeout = timeout; // time in milliseconds
  xhr.setRequestHeader("Content-type", "application/json");

  logger.logMessage(LogLevel.Info, `Calling api, url:${url}`)

  if(token)
    xhr.setRequestHeader("Authorization", token);

  xhr.onload = () => {
    if (xhr.status >= 200 && xhr.status < 300) {
      resolve(JSON.parse(xhr.response));
    } else {
      logger.logApiMessage(xhr.response);
      var response = JSON.parse(xhr.response);
      reject({
        errors:response.errors,
        statusCode:xhr.status
      });
    }
  };
  xhr.onerror = () => {
    logger.logMessage(LogLevel.Warn, `Failed calling api, url:${url}, response:${xhr.statusText}`)
    reject(xhr.statusText);
  }

  xhr.ontimeout = function (e) {
    logger.logMessage(LogLevel.Warn, `Timeout calling api, url:${url}, response:${xhr.statusText}`)
    reject('timeout');
  };

  if(entity)
    xhr.send(JSON.stringify(entity));
  else
    xhr.send();
}

export class SutwokuApiClient {
  static constitute () { }
  constructor(logger, appConfig) {
    this.logger = logger;
    this.appConfig = appConfig;
  }
  register(account){
    return new Promise((resolve, reject) => {
      doRequest("POST", account, generateUrl(this.appConfig, 'account/signup'), this.logger, resolve, reject, null, 5000);
    });
  }
  convert(account, jwtToken){
    return new Promise((resolve, reject) => {
      doRequest("POST", account, generateUrl(this.appConfig, 'account/convert'), this.logger, resolve, reject, jwtToken, 5000);
    });
  }
  getAccount(account){
    return new Promise((resolve, reject) => {
      doRequest("GET", null, generateUrl(this.appConfig, 'account'), this.logger, resolve, reject, account.jwtToken);
    });
  }
  signIn(account){
    return new Promise((resolve, reject) => {
      doRequest("POST", account, generateUrl(this.appConfig, 'account/login'), this.logger, resolve, reject, null, 5000);
    });
  }
  getGame(gameRef, jwtToken){
    return new Promise((resolve, reject) => {
      doRequest("GET", null, generateUrl(this.appConfig, 'game/ref/' + gameRef), this.logger, resolve, reject, jwtToken);
    });
  }
  getAccountGameStats(account, gameLevel){
    return new Promise((resolve, reject) => {
      doRequest("GET", null, generateUrl(this.appConfig, 'stats/games/' + account.id + '?gameLevel=' + gameLevel), this.logger, resolve, reject, account.jwtToken);
    });
  }
  getRankingCountStats(account, gameLevel){
    return new Promise((resolve, reject) => {
      doRequest("GET", null, generateUrl(this.appConfig, 'stats/ranking/count/' + account.id + '?gameLevel=' + gameLevel), this.logger, resolve, reject, account.jwtToken);
    });
  }
  getRankingStats(account, gameLevel){
    return new Promise((resolve, reject) => {
      doRequest("GET", null, generateUrl(this.appConfig, 'stats/ranking/' + account.id + '?gameLevel=' + gameLevel), this.logger, resolve, reject, account.jwtToken);
    });
  }
  getFriendGameStats(account){
    return new Promise((resolve, reject) => {
      doRequest("GET", null, generateUrl(this.appConfig, 'stats/games/friend/' + account.id), this.logger, resolve, reject, account.jwtToken);
    });
  }
}

