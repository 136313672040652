import { connect } from 'react-redux'
import GuestComponent from '../../components/account/guest'

import {createGuestAccount, getAccount} from './../../actions/account'


const mapStateToProps = state => {
  return {
    account:state.account
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createGuestAccount:() => dispatch(createGuestAccount()),
    getAccount:() => dispatch(getAccount())
  }
}
 
const GuestContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GuestComponent)
 
export default GuestContainer