import MessageType from './message-type';

export class MessageFactory {
  createConnectionMessage(account, gameRef, gameType, gameLevel, isBotGame){
    var relevantRank = account.ratings.filter(r => r.gameLevel == gameLevel)[0].rating;

    return {
      messageType:MessageType.connection,
      userId:account.id,
      userRank:relevantRank,
      name:account.name,
      gameRef,
      gameType,
      gameLevel,
      isBotGame
    };
  }
  createGuessMessage(gameRef, column, row, value){
    return {
      messageType:MessageType.guess,
      gameRef,
      column,
      row,
      value
    };
  }
  createInitBotMessage(){
    return {
      messageType:MessageType.initBot
    };
  }
  createReconnectionMessage(gameRef, accountId){
    return {
      messageType:MessageType.reconnection,
      gameRef,
      accountId
    };
  }
  createBotMoveMessage(gameRef, isCorrect){
    return {
      gameRef,
      messageType:MessageType.botMove,
      isCorrect
    };
  }
}

