import { connect } from 'react-redux'
import MatchMakerComponent from './../components/match-maker/match-maker'

import {getAccount} from './../actions/account'
import {toggleThemeMode, loadSettings} from './../actions/settings'
import {connectToServer, 
  initFriendGame, 
  initState, 
  disconnectFromServer, 
  joinRandomGame,
  leaveQueue,
  logAccordianSelection,
  getFriendGameStats} from '../actions/match-maker'

const mapStateToProps = state => {
  return {
    account:state.account,
    activeTheme:state.settings.themeType,
    matchMaker:state.matchMaker,
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    connectToServer:() => dispatch(connectToServer()),
    initFriendGame:(friendName) => dispatch(initFriendGame(friendName)),
    toggleTheme:() => dispatch(toggleThemeMode()),
    getAccount:() => dispatch(getAccount()),
    loadSettings:() => dispatch(loadSettings()),
    initState:() => dispatch(initState()),
    disconnectFromServer:() => dispatch(disconnectFromServer()),
    joinRandomGame:(gameType) => dispatch(joinRandomGame(gameType)),
    leaveQueue:() => dispatch(leaveQueue()),
    logAccordianSelection:(accordianType) => dispatch(logAccordianSelection(accordianType)),
    getFriendGameStats:() => dispatch(getFriendGameStats())
  }
}
 
const MatchMakerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MatchMakerComponent)
 
export default MatchMakerContainer