import MessageType from './message-type';

export class MessageHandlerFactory {
  constructor(defaultMessageHandler, 
      opponentFoundMessageHandler, 
      startGameMessageHandler,
      guessResultMessageHandler,
      scoreUpdateMessageHandler,
      connectionSuccessMessageHandler,
      finishGameMessageHandler,
      botSettingMessageHandler,
      disconnectionMessageHandler,
      reconnectionMessageHandler) {
    this.defaultMessageHandler = defaultMessageHandler;
    this.opponentFoundMessageHandler = opponentFoundMessageHandler;
    this.startGameMessageHandler = startGameMessageHandler;
    this.guessResultMessageHandler = guessResultMessageHandler;
    this.scoreUpdateMessageHandler = scoreUpdateMessageHandler;
    this.connectionSuccessMessageHandler = connectionSuccessMessageHandler;
    this.finishGameMessageHandler = finishGameMessageHandler;
    this.botSettingMessageHandler = botSettingMessageHandler;
    this.disconnectionMessageHandler = disconnectionMessageHandler;
    this.reconnectionMessageHandler = reconnectionMessageHandler;
  }
  createMessageHandler(messageType){
    switch(messageType){
      case MessageType.opponentFound:
        return this.opponentFoundMessageHandler;
      case MessageType.startGame:
        return this.startGameMessageHandler;
      case MessageType.guessResult:
        return this.guessResultMessageHandler;
      case MessageType.scoreUpdate:
        return this.scoreUpdateMessageHandler;
      case MessageType.connectionSuccess:
        return this.connectionSuccessMessageHandler;
      case MessageType.finishGame:
        return this.finishGameMessageHandler;
      case MessageType.botSettings:
        return this.botSettingMessageHandler;
      case MessageType.disconnection:
        return this.disconnectionMessageHandler;
      case MessageType.reconnectionSuccess:
        return this.reconnectionMessageHandler;
      default:
        return this.defaultMessageHandler;
    }
  }
}

