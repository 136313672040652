import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  paper:{
    height:'100%'
  },
  content:{
    height:'100%',
    width:'100%', 
    display: 'flex'
  }, 
  text:{  
    margin: 'auto'
  }
}));
export default function SpecialButton(props) {
  const classes = useStyles();
  return (
    <Box width='27.33%' height="100%" p='0' m='3%' display="inline-block">
      <Paper className={classes.paper} onClick={() => props.doButtonClick()} elevation={3}>
        <Typography className={classes.content} component="div">
          <Box className={classes.text}>
            {props.children}
          </Box>
        </Typography>
      </Paper>
    </Box>  
  );
}