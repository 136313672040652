import { LogLevel } from '../logging/logger';
import MessageType from './../messaging/message-type' 

export class SocketService {
  constructor(url, logger) {
    this.url = url;
    this.logger = logger
  }
  openSocket(onMessageCb){
    return new Promise((resolve, reject) => {
      
      if(this.socket){
        if(this.socket.readState == 1){
          this.onMessageCb = onMessageCb;
          resolve();
        }else if(this.socket.readyState == 0){
          setTimeout(() => {
            this.onMessageCb = onMessageCb;
            resolve();
          }, 1000)
        }
      }

      this.socket = new WebSocket(this.url);
      this.onMessageCb = onMessageCb;
      var self = this;
      this.socket.onopen = function(){
        self.logger.logMessage(LogLevel.Info, `Socket: Connection Open -> ${self.url}`)
        self.socket.onmessage = self.onMessage.bind(self);
        resolve();
      }
      this.socket.onerror = function(event) {
        self.logger.logMessage(LogLevel.Error, `Socket: Error -> ${self.url} -> ${JSON.stringify(event)}`)
      };
      this.socket.onclose= function(){
        self.logger.logMessage(LogLevel.Warn, `Socket: Connection Closed -> ${self.url}`)
        self.onMessageCb({
          messageType:MessageType.disconnection
        });
      }
    });
  }
  disconnect(){
    if(this.socket){
      this.socket.onclose = null
      if(this.socket.readyState != 3 && this.socket.readyState != 2)
        this.logger.logMessage(LogLevel.Info, `Socket: Closing Connection -> ${this.url}`)
        this.socket.close();
    }
  }
  onMessage(event){
    this.onMessageCb(JSON.parse(event.data));
  }
  sendMessage(message){
    var jsonMessage = JSON.stringify(message);
    this.logger.logMessage(LogLevel.Debug, `Socket: Sending Message -> ${jsonMessage}`);
    this.socket.send(jsonMessage);
  }
}

