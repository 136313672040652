class MessageType {
  constructor() {
    this.connection = 'Connection';
    this.opponentFound = 'OpponentFound';
    this.puzzleUpdate = 'PuzzleUpdate';
    this.startGame = 'StartGame';
    this.guess = 'Guess';
    this.guessResult = 'GuessResult';
    this.scoreUpdate = 'ScoreUpdate';
    this.connectionSuccess = 'ConnectionSuccess';
    this.finishGame = 'FinishGame';
    this.initBot = 'InitBot';
    this.botMove = 'BotMove';
    this.botSettings = 'BotSettings';
    this.disconnection = 'Disconnection';
    this.reconnection = 'Reconnection';
    this.reconnectionSuccess = 'ReconnectionSuccess';
    this.friendSelection = 'FriendSelection';
  }
}

export default new MessageType();