import {SET_MODAL_SETTINGS, SET_MOBILE_MODAL_SHOWN, SET_SHOW_RATINGS_MODAL_SHOWN} from './../actions/modals'

const modals = (state, action) => {
  if(!state)
    state = {}

  switch(action.type){
    case SET_MODAL_SETTINGS:
      state = {...state, ratingModal:action.modalSettings.ratingModal}
      return state;
    case SET_SHOW_RATINGS_MODAL_SHOWN:
      state = {...state, ratingModal:{
        hasShown:true,
        shownAt:new Date()
      }}
    case SET_MOBILE_MODAL_SHOWN:
      state = {...state, mobileModalShown:true}
    default:
      return state;
  }
};

export default modals;