var getNextInterval = (settings) => {
  var min = settings.averageGuessInterval - (settings.averageGuessInterval * settings.guessIntervalRange / 100 );
  var max = settings.averageGuessInterval + (settings.averageGuessInterval * settings.guessIntervalRange / 100 );

  return Math.floor(Math.random() * (max - min + 1)) + min;
}

var isCorrect = (settings) => {
  return Math.random() < (settings.averageGuessAccuracy / 100);
}

export class Bot {
  constructor(apiClient, messageManager) {
    this.apiClient = apiClient;
    this.messageManager = messageManager; 
    //default settings
    this.settings = {
      averageGuessInterval:8,
      guessIntervalRange:60,
      averageGuessAccuracy:80
    };
  }
  init(gameId){
    this.gameId = gameId;
    this.doGuess(getNextInterval(this.settings));
  }
  setServerSettings(serverSettings){
    this.settings = serverSettings;
    this.doGuess(getNextInterval(this.settings));
  }
  dispose(){
    clearTimeout(this.interval);
  }
  doGuess(nextGuessInterval){
    if(this.interval){
      clearInterval(this.interval);
    }

    var self = this;
    this.interval = setTimeout(() => {
      if(self.gameId){
        self.messageManager.sendBotMoveMessage(self.gameId, isCorrect(self.settings));
        self.doGuess(getNextInterval(self.settings))
      }
    }, nextGuessInterval * 1000);
  }
}

