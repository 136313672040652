import React from "react";

import Box from '@material-ui/core/Box';
import Tile from './tile';

var getTile = (tiles, row, column) => {
  if(tiles && tiles.length > 0){
    return tiles[row][column];
  }else{
    return {}
  }
}

export default function Square(props) {
  return (
      <Box width='100%' height="100%" m="0%">
        <Tile tile={getTile(props.tiles, 0, 0)} doTileClick={props.doTileClick} />
        <Tile tile={getTile(props.tiles, 0, 1)} doTileClick={props.doTileClick} />
        <Tile tile={getTile(props.tiles, 0, 2)} doTileClick={props.doTileClick} />
        <Tile tile={getTile(props.tiles, 1, 0)} doTileClick={props.doTileClick} />
        <Tile tile={getTile(props.tiles, 1, 1)} doTileClick={props.doTileClick} />
        <Tile tile={getTile(props.tiles, 1, 2)} doTileClick={props.doTileClick} />
        <Tile tile={getTile(props.tiles, 2, 0)} doTileClick={props.doTileClick} />
        <Tile tile={getTile(props.tiles, 2, 1)} doTileClick={props.doTileClick} />
        <Tile tile={getTile(props.tiles, 2, 2)} doTileClick={props.doTileClick} />
      </Box>
  );
}