import {LogLevel} from './../services/logging/logger'

export const SET_GAME_STATS = 'SET_GAME_STATS';
export const SET_RANKING_COUNT_STATS = 'SET_RANKING_COUNT_STATS';
export const SET_RANKING_STATS = 'SET_RANKING_STATS';
export const SET_DIFFICULTY = 'SET_DIFFICULTY';

export const getStats = () => {
  return (dispatch, getState, services) => {
    var difficulty = getState().stats.difficulty
    var account = getState().account
    services.apiClient.getAccountGameStats(account, difficulty)
      .then(stats => {
          dispatch(setGameStats(stats));
      });
    services.apiClient.getRankingCountStats(account, difficulty)
      .then(stats => {
          dispatch(setRankingCountStats(stats));
      });
    services.apiClient.getRankingStats(account, difficulty)
      .then(stats => {
        dispatch(setRankingStats(stats));
      });
  }
};

export const setGameStats = (gameStats) => {
  return {
      type: SET_GAME_STATS,
      stats:gameStats
  }
};

export const changeDifficulty = (difficulty) => {
  return (dispatch, getState, services) => {
    services.logger.logMessage(LogLevel.Debug, `Stats : Change Difficulty -> ${difficulty}`);
    dispatch(setDifficulty(difficulty))
  }
};

export const setDifficulty = (difficulty) => {
  return {
      type: SET_DIFFICULTY,
      difficulty
  }
};

export const setRankingCountStats = (rankingCountStats) => {
  return {
      type: SET_RANKING_COUNT_STATS,
      stats:rankingCountStats
  }
};

export const setRankingStats = (rankingStats) => {
  return {
      type: SET_RANKING_STATS,
      stats:rankingStats
  }
};