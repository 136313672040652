import {LogLevel} from './../services/logging/logger'

export const SET_GAMEOVER = 'SET_GAMEOVER';
export const CLEAR_GAMEOVER = 'CLEAR_GAMEOVER';

const loadGameOverWithRetries = (services, game, user, dispatch, opponent, numberOfRetries) => {
  if(numberOfRetries > 10)
    return;

    services.logger.logMessage(LogLevel.Info, `Getting GameOverStats, GameId:${game.gameRef}`);

  services.apiClient.getGame(game.gameRef, user.jwtToken)
    .then(data => {
      dispatch(setGameover(data, user, opponent))
    }, 
    err => {
      services.logger.logMessage(LogLevel.Warn, `Error getting game over stats, GameId:${game.gameRef}, ${err}`);
      if(err ==='timeout'){
        loadGameOverWithRetries(services, game, user, dispatch, opponent, ++numberOfRetries )
      }

      if(err.statusCode === 404){
        setTimeout(() => {
          loadGameOverWithRetries(services, game, user, dispatch, opponent, ++numberOfRetries )
        }, 2500);
      }
  });
}

export const loadGameOver = () => {
  return (dispatch, getState, services) => {
    return services.storage.get("account")
      .then(account => {
        services.storage.get("game")
          .then(game => {
            services.storage.get("opponent")
              .then(opponent => {
                loadGameOverWithRetries(services, game, account, dispatch, opponent, 0)
              });
          });
      });
  }
};

export const recordSocialClick = (socialType) => {
  return (dispatch, getState, services) => {
    return services.logger.logMessage(LogLevel.Info, `Social Share Click : ${socialType}`)
  }
};

export const setGameover = (data, user, opponent) => {
  return {
    type: SET_GAMEOVER,
    game:data,
    user,
    opponent
  }
};

export const clearGameover = () => {
  return {
    type: CLEAR_GAMEOVER
  }
};