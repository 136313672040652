import React from "react";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';

import Board from './board/board';
import ButtonsContainer from './buttons/buttons'
import Loading from './loading'
import Scoreboard from './scoreboard/scoreboard'
import Friend from './friend/friend'

import Grid from "@material-ui/core/Grid";
import Slide from '@material-ui/core/Slide';

const styles = theme => ({
  root: {
    height:'100%',
    paddingTop:theme.spacing(1)
  },
});


class GameComponent extends React.Component {
  constructor(){
    super();
    this.state ={
      target:'/home',
      show:true
    }
  }
  componentDidMount(){
    this.props.cleanUpGame()
      .then(() => {
        if(this.account == null)
          this.props.getAccount()
            .then(account => {
              this.props.connectToServer(account);
            });
      });
  }
  componentWillUnmount(){
    this.props.disconnectFromServer();
  }
  onExited(){
    this.props.history.push(this.state.target)
  }
  beginExit(target){
    this.setState({
      target,
      show:!this.state.show
    });
  };
  render() {
    if(this.props.game.gameState === 'FINISHED'){
      setTimeout(() => {
        this.beginExit('/gameover')
      }, 2500)
    }
    const { classes } = this.props;

    var shouldShowFriendModal = false;
    var shouldShowLoading = false;

    if(this.props.game.gameState !== 'IN_PROGRESS'){
      shouldShowLoading = true;
    }

    return(
      <Slide className={classes.root} direction="left" in={true} mountOnEnter unmountOnExit onExited={this.onExited.bind(this)} in={this.state.show} mountOnEnter unmountOnExit>
        <Container className={classes.root}>
          <Grid className={classes.root} justify="center" container >
            <Friend show={shouldShowFriendModal}
                onSubmit={this.props.sendFriendSelectionMessage}
                onBack={this.beginExit.bind(this)}/>
            <Loading show={shouldShowLoading} 
              error={this.props.game.gameState === 'BLOCKED'} 
              text={this.props.game.message}/>
            <Grid item xs={12}>
              <Scoreboard scores={this.props.game.scoreboard}/>
            </Grid>
            <Grid item xs={12}>
              <Board puzzle={this.props.puzzle} doTileClick={this.props.doTileClick}/>
            </Grid>
            <Grid item xs={12}>
              <ButtonsContainer />
            </Grid>
          </Grid>
        </Container>
      </Slide>
    )
  }
}
GameComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GameComponent);