import {SET_ACCOUNT} from './../actions/account'

const account = (state, action) => {
  if(!state)
    state = {}

  switch(action.type){
    case SET_ACCOUNT:
      state = action.account;
      return state;
    default:
      return state;
  }
};

export default account;