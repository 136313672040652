import {LogLevel} from './../../logging/logger'
import {setOpponentDetails} from './../../../actions/game';

export class OpponentFoundMessageHandler {
  constructor(logger, storage) {
    this.logger = logger;
    this.storage = storage;
  }
  handleMessage(message, dispatch){
    this.logger.logMessage(LogLevel.Debug, 'Handling message in opponentFound handler, Message : ' + JSON.stringify(message));
    this.storage.save("opponent", {name:message.name, rank:message.rank});
    dispatch(setOpponentDetails(message.name, message.rank));
    return Promise.resolve();
  }
}

