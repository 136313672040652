import {SET_MESSAGE, 
  SET_STATUS, 
  SET_SCORE, 
  CLEAN_UP_GAME, 
  SET_GAME, 
  SET_OPPONENT_DETAILS, 
  SET_PLAYER_DETAILS, 
  SET_TYPE} from './../actions/game'

var setPuzzle = (rawPuzzle) => {
  var puzzle = []

  for(var i = 0;i<9;i++){
    var row = []
    for(var j = 0;j<9;j++){
      var tile = tileFactory.createTile(rawPuzzle[i][j]);
      tile.col = j;
      tile.row = i;
      row.push(tile);
    }
    puzzle.push(row);
  }

  return puzzle;
}

const defaultState = {
  message:"Connecting",
  scoreboard:{yourScore:0, 
    playerDetails:{},
    theirScore:0,
    theirDetails:{},
  },
  gameState:'INITIATING',
  hasStarted:false
}

const game = (state, action) => {
  if(!state)
    state = defaultState;
    
  switch(action.type){
    case CLEAN_UP_GAME:
      return Object.assign({}, state, defaultState) 
    case SET_MESSAGE:
      return Object.assign({}, state, {message:action.message, icon:action.icon})
    case SET_STATUS:
      return Object.assign({}, state, {gameState:action.status})
    case SET_TYPE:
      return Object.assign({}, state, {gameType:action.gameType})
    case SET_GAME:
      return Object.assign({}, state, {gameRef:action.gameRef, gameType: action.gameType, gameLevel: action.gameLevel})
    case SET_SCORE:
      return {...state, scoreboard:{...state.scoreboard, yourScore:action.yourScore, theirScore:action.theirScore}}
    case SET_OPPONENT_DETAILS:
      return {...state, scoreboard:{...state.scoreboard, theirDetails:{name:action.name, rank:action.rank}}}
    case SET_PLAYER_DETAILS:
      return {...state, scoreboard:{...state.scoreboard, playerDetails:{name:action.name, rank:action.rank}}}
    default:
      return state;
  }
};

export default game;