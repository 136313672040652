export class MatchMakingService {
  constructor(matchMakingSocket, messageFactory, matchMakingMessageHandlerFactory) {
    this.matchMakingSocket = matchMakingSocket;
    this.messageFactory = messageFactory;
    this.matchMakingMessageHandlerFactory = matchMakingMessageHandlerFactory
  }
  openSocket(messageCallback){
    this.messageCallback = messageCallback;
    return this.matchMakingSocket.openSocket(this.onMessage.bind(this));
  }
  disconnect(){
    return this.matchMakingSocket.disconnect();
  }
  onMessage(message){
    var messageHandler = this.matchMakingMessageHandlerFactory.createMessageHandler(message.messageType);
    return messageHandler.handleMessage(message, this.messageCallback);
  }
  sendConnectionMessage(account){
    this.matchMakingSocket.sendMessage(this.messageFactory.createConnectionMessage(account));
    return Promise.resolve(); 
  }
  sendFriendSelectionMessage(friendName, gameLevel){
    this.matchMakingSocket.sendMessage(this.messageFactory.createFriendSelectionMessage(friendName, gameLevel));
    return Promise.resolve(); 
  }
  sendAcceptFriendGameMessage(){
    this.matchMakingSocket.sendMessage(this.messageFactory.createAcceptFriendGameMessage());
    return Promise.resolve(); 
  }
  sendRejectFriendGameMessage(){
    this.matchMakingSocket.sendMessage(this.messageFactory.createRejectFriendGameMessage());
    return Promise.resolve(); 
  }
  sendJoinRandomGameMessage(gameType, gameLevel){
    this.matchMakingSocket.sendMessage(this.messageFactory.createJoinRandomGameMessage(gameType, gameLevel));
    return Promise.resolve(); 
  }
  sendInitBotMessage(gameType, gameLevel){
    this.matchMakingSocket.sendMessage(this.messageFactory.createInitBotMessage(gameType, gameLevel));
    return Promise.resolve(); 
  }
  sendLeaveQueueMessage(){
    this.matchMakingSocket.sendMessage(this.messageFactory.createLeaveQueueMessage());
    return Promise.resolve(); 
  }
}

