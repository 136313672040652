import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import {Card, 
  Typography, 
  Divider, 
  CircularProgress,
  Box} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
  }
}));

export default function Player(props) {
  const classes = useStyles();
  var details = props.details ?? {};
  var playerTheme = props.player ? classes.player : classes.opponent;

  return (
    <Card className={classes.card}>
      <Box width='100%' textAlign='center'>
          <Typography component="div" variant="body2">
            <Box fontWeight="fontWeightBold" m={0.5}>
              {props.name}
            </Box>
          </Typography> 
        </Box>
        <Divider />
        <Box width='50%' textAlign='center' display='inline-block'>
          <Typography component="div" variant="body2">
            <Box m={0.5}>
            {props.you &&
              props.you
            }
            {(!props.you && props.you != 0) &&
              <CircularProgress size='0.875rem'/>
            }
            </Box>
          </Typography> 
        </Box>
        <Box width='50%' textAlign='center' display='inline-block'>
          <Typography component="div" variant="body2">
            <Box m={0.5}>
            {props.them &&
              props.them
            }
            {(!props.them && props.them != 0) &&
              <CircularProgress size='0.875rem'/>
            }
            </Box>
          </Typography> 
        </Box>
    </Card>
  );
}