import {SET_GAME_STATS, SET_RANKING_COUNT_STATS, SET_RANKING_STATS, SET_DIFFICULTY} from './../actions/stats'

const stats = (state, action) => {
  if(!state)
    state = {
      difficulty:"EASY"
    }

  switch(action.type){
    case SET_GAME_STATS:
      return Object.assign({}, state, {gameStats:action.stats})
    case SET_RANKING_COUNT_STATS:
      var percentile = 0;  

      if(action.stats.totalCount > 0){
        var percentileFloat = ((action.stats.totalCount - action.stats.greaterThanCount) + ((action.stats.equalCount - 1) / 2)) * 100 / action.stats.totalCount;


        if(percentileFloat > 99.9){
          percentile = percentileFloat.toFixed(2)
        }
        else{
          percentile = percentileFloat.toFixed(1)
        }

        if(percentile >= 99.98)
          percentile = 99.98;
      }
      return Object.assign({}, state, 
        {rankingCountStats:{percentile, currentRating:action.stats.ranking, ranking:action.stats.greaterThanCount + 1}})
    case SET_RANKING_STATS:
      return Object.assign({}, state, {rankingStats:action.stats})
    case SET_DIFFICULTY:
      return state = {
        difficulty:action.difficulty
      }
    default:
      return state;
  }
};

export default stats;