import {LogLevel} from './../../logging/logger'

export class BotSettingMessageHandler {
  constructor(logger, services) {
    this.logger = logger;
    this.services = services;
  }
  handleMessage(message, dispatch){
    this.logger.logMessage(LogLevel.Debug, 'Handling connection success message, Message : ' + JSON.stringify(message));
    this.services.bot.setServerSettings(message)
    return Promise.resolve();
  }
}
