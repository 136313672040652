import React from "react";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import { Grid, Box} from "@material-ui/core";

import Player from './player'
import Stat from './stat'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faTwitter
} from '@fortawesome/free-brands-svg-icons'

const styles = theme => ({
  root: {
    height:'100%',
    paddingTop:theme.spacing(1)
  },
  button: {
    width:'100%'
  },
  twitterButton: {
    color:'#1DA1F2',
    borderColor:'#1DA1F2',
    width:'100%'
  },
  twitterIcon: {
    marginRight:theme.spacing(1)
  }
});


class GameOverComponent extends React.Component {
  constructor(){
    super();
    this.state ={
      show:true
    }
  }
  componentDidMount(){
    this.props.cleanUpGame()
      .then(() => {
        this.props.loadSettings();
        this.props.loadGameOver();
      });
  }
  onExited(){
    this.props.history.push(this.state.target)
  }
  beginExit(target){
    this.setState({
      target,
      show:!this.state.show
    });
  };
  recordTwitterClick(socialTyle){
    this.props.recordSocialClick(socialTyle);
  }
  render() {
    const { classes } = this.props;
    return(
      <Slide className={classes.root} direction="left" onExited={this.onExited.bind(this)} in={this.state.show} mountOnEnter unmountOnExit>
        <Container className={classes.root} id='home-container' maxWidth="lg">
            <Grid container spacing={2} className={classes.root} >
              <Grid item xs={6}>
                <Player details={this.props.data ? this.props.data.you : {}} player/>
              </Grid>
              <Grid item xs={6}>
                <Player details={this.props.data ? this.props.data.them : {}}/>
              </Grid>
              <Grid item xs={12}>
                <Stat name='Score' you={(this.props.data && this.props.data.you) 
                  ? this.props.data.you.score : null}
                  them={(this.props.data && this.props.data.them) 
                    ? this.props.data.them.score : null}/>
              </Grid>
              <Grid item xs={12}>
                <Stat name='Accuracy' you={(this.props.data && this.props.data.you) 
                  ? this.props.data.you.accuracy + '%' : null}
                  them={(this.props.data && this.props.data.them) 
                    ? this.props.data.them.accuracy + '%' : null}/>
              </Grid>
              <Grid item xs={12}>
                <Stat name='No Of Guesses' you={(this.props.data && this.props.data.you) 
                  ? this.props.data.you.noOfGuesses : null}
                  them={(this.props.data && this.props.data.them) 
                    ? this.props.data.them.noOfGuesses : null}/>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {this.props.data.you &&
                    this.props.data.you.result === 'winner' &&
                    <Grid item xs={12}>
                      <Box display="flex" flexDirection="row-reverse">
                        <Button className={classes.twitterButton}
                        size='small'
                        variant='outlined'
                        onClick={() => this.recordTwitterClick('Twitter')}
                        href='https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.sutwoku.io%2F&via=sutwoku&text=I%20just%20won%20a%20game%20of%20Sutwoku%2C%20come%20on%20over%20and%20challenge%20me%20at&hashtags=Sudoku%2CSutwoku' 
                        target="_blank">
                          <FontAwesomeIcon 
                          size='lg'
                          className={classes.twitterIcon} 
                          icon={faTwitter}/> 
                          Tweet Your Victory
                        </Button>
                      </Box>
                    </Grid>
                  }
                  <Grid item xs={6}>
                    <Button className={classes.button} variant="contained" size="small" 
                    color="primary" onClick={() => this.beginExit('/match-maker')}>
                      Play Again
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button className={classes.button} variant="contained" size="small" 
                    color="secondary" onClick={() => this.beginExit('/home')}>
                      Home
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
           
        </Container>
      </Slide>
    )
  }
}

GameOverComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GameOverComponent);