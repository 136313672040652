import {LogLevel} from './../../logging/logger'
import {handleGuessResult} from './../../../actions/game';

export class GuessResultHandler {
  constructor(logger) {
    this.logger = logger;
  }
  handleMessage(message, dispatch){
    this.logger.logMessage(LogLevel.Debug, 'Handling message in guess result handler, Message : ' + JSON.stringify(message));
    dispatch(handleGuessResult(message));
    return Promise.resolve();
  }
}

