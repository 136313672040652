import {LogLevel} from './../../logging/logger'

export class DefaultMessageHandler {
  constructor(logger) {
    this.logger = logger;
  }
  handleMessage(message){
    this.logger.logMessage(LogLevel.Debug, 'Handling message in game default message handler, Message : ' + JSON.stringify(message));
    return Promise.resolve();
  }
}

