import {LogLevel} from './../services/logging/logger'

export const SET_ACCOUNT = 'SET_ACCOUNT';

export const setAccount = (account) => {
  return {
    type: SET_ACCOUNT,
    account
  }
};

export const getAccount = (refresh) => {
  return(dispatch, getState, services) => {
    return services.storage.get('account')
      .then(account => {
        if(refresh){
          return services.apiClient.getAccount(account)
            .then(updatedAccount => {
              updatedAccount.jwtToken = account.jwtToken;
              services.storage.save('account', updatedAccount)
              dispatch(setAccount(updatedAccount))
              return updatedAccount;
            })
        }
        else{
          dispatch(setAccount(account))
          return account;
        }
      })
  };
};

export const createAccount = (account) => {
  return(dispatch, getState, services) => {
    var existingAccount = getState().account;

    if(existingAccount == null){
      services.logger.logMessage(LogLevel.Info, 'Registering Account')
      return new Promise((resolve, reject) => {
        services.apiClient.register(account)
          .then(createdAccount => {
            services.storage.save('account', createdAccount)
            dispatch(setAccount(createdAccount))
            resolve();
          })
          .catch(error => {
            reject(error);
          });
        });
    }else{
      return new Promise((resolve, reject) => {
        services.apiClient.convert(account, existingAccount.jwtToken)
          .then(createdAccount => {
            services.storage.save('account', createdAccount)
            dispatch(setAccount(createdAccount))
            resolve();
          })
          .catch(error => {
            reject(error);
          });
        });
    }
  };
};

export const signIn = (account) => {
  return(dispatch, getState, services) => {
    services.logger.logMessage(LogLevel.Info, 'Signing In')
    return new Promise((resolve, reject) => {
      services.apiClient.signIn(account)
        .then(signInAccount => {
          services.storage.save('account', signInAccount)
          dispatch(setAccount(signInAccount))
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
  };
};

export const createGuestAccount = () => {
  return(dispatch, getState, services) => {
    services.logger.logMessage(LogLevel.Info, 'Creating Guest Account')
    var account = { "name":"guest", "password":"password", guest:true}
    return dispatch(createAccount(account))
  };
};

export const unregister = () => {
  return (dispatch, getState, services) => {
    services.logger.logMessage(LogLevel.Info, 'Unregistering Account')
    return services.storage.delete('account');
  }
};