import {LogLevel} from './../../logging/logger'

export class DefaultMatchMakingMessageHandler {
  constructor(logger) {
    this.logger = logger;
  }
  handleMessage(message){
    this.logger.logMessage(LogLevel.Debug, 'Handling message in match maker default message handler, Message : ' + JSON.stringify(message));
  }
}

