import MessageType from './match-making-message-type';

export class MatchMakingMessageFactory {
  createConnectionMessage(account){
    return {
      messageType:MessageType.connection,
      id:account.id,
      rank:account.ranking,
      name:account.name
    };
  }
  createFriendSelectionMessage(friendName, gameLevel){
    return {
      messageType:MessageType.friendSelection,
      friendName,
      gameLevel
    };
  }
  createAcceptFriendGameMessage(){
    return {
      messageType:MessageType.acceptFriendGame
    };
  }
  createRejectFriendGameMessage(){
    return {
      messageType:MessageType.rejectFriendGame
    };
  }
  createJoinRandomGameMessage(gameType, gameLevel){
    return {
      messageType:MessageType.joinRandomGame,
      gameType, 
      gameLevel
    };
  }
  createInitBotMessage(gameType, gameLevel){
    return {
      messageType:MessageType.initBot,
      gameType,
      gameLevel
    };
  }
  createLeaveQueueMessage(){
    return {
      messageType:MessageType.leaveQueue
    };
  }
}