class MessageType {
  constructor() {
    this.connection = 'Connection';
    this.friendSelection = 'FriendSelection';
    this.friendWaiting = 'FriendWaiting';
    this.acceptFriendGame = 'AcceptFriendGame';
    this.rejectFriendGame = 'RejectFriendGame';
    this.gameFound = 'GameFound';
    this.joinRandomGame = 'JoinRandomGame'
    this.initBot = 'InitBot'
    this.botSettings = 'BotSettings'
    this.leaveQueue = 'LeaveQueue'
    this.friendRejectedGame = 'FriendRejectedGame'
  }
}

export default new MessageType();