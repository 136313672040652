export class MessageManager {
  constructor(messageFactory, gameSocketService, logger, messageHandlerFactory) {
    this.messageFactory = messageFactory;
    this.gameSocketService = gameSocketService;
    this.logger = logger;
    this.messageHandlerFactory = messageHandlerFactory;
  }
  openSocket(messageCallback){
    this.messageCallback = messageCallback;
    return this.gameSocketService.openSocket(this.onMessage.bind(this));
  }
  onMessage(message){
    var messageHandler = this.messageHandlerFactory.createMessageHandler(message.messageType);
    return messageHandler.handleMessage(message, this.messageCallback);
  }
  sendConnectionMessage(account, gameRef, gameType, gameLevel, isBotGame){
    var message = this.messageFactory.createConnectionMessage(account, gameRef, gameType, gameLevel, isBotGame);
    this.gameSocketService.sendMessage(message);
    return Promise.resolve();
  }
  sendFriendSelectionMessage(account, friendName){
    var message = this.messageFactory.createFriendSelectionMessage(account.name, friendName);
    this.gameSocketService.sendMessage(message);
    return Promise.resolve();
  }
  disconnect(){
    this.gameSocketService.disconnect();
  }
  attemptToReconnect(gameId, accountId, messageCallback){
    this.openSocket(messageCallback)
      .then(() => {
        var reconnectMessage = this.messageFactory.createReconnectionMessage(gameId, accountId)
        this.gameSocketService.sendMessage(reconnectMessage);
        return Promise.resolve();
      });
  }
  sendGuessMessage(gameRef, col, row, value){
    var message = this.messageFactory.createGuessMessage(gameRef, col, row, value);
    this.gameSocketService.sendMessage(message);
    return Promise.resolve();
  }
  sendInitBotMessage(){
    var message = this.messageFactory.createInitBotMessage();
    this.socketService.sendMessage(message);
    return Promise.resolve();
  }
  sendBotMoveMessage(gameId, isCorrect){
    var message = this.messageFactory.createBotMoveMessage(gameId, isCorrect);
    this.gameSocketService.sendMessage(message);
    return Promise.resolve();
  }
}

