import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {ExpansionPanelDetails,
  Button,
  Box,
  Backdrop,
  Paper,
} from '@material-ui/core';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backdropFilter: 'blur(4px)'
  },
  paper:{
    width:'60%'
  },
  field:{
    width:'100%'
  },
  form:{
    width:'90%',
    margin:'auto'
  },
  detail:{
    padding:theme.spacing(1)
  }
}));

export default function FriendPanel(props) {
  const classes = useStyles();
  
  return (
    <div >
      <ExpansionPanelDetails className={classes.detail}>
        <Formik
          initialValues={{
            username: ''
          }}
          validate={values => {
            const errors = {};
            if (values.username.length > 0) {
              if (values.username.length < 3) {
                errors.username = 'Minimum username length is 3 characters';
              } else if (values.username.length > 12) {
                errors.username = 'Maximum username length is 12 characters';
              }
            }else{
              errors.username = 'Please enter you friends name here';
            }

            return errors;
          }}
          onSubmit={(values, {setSubmitting, setErrors}) => {
            props.initFriendGame(values.username);
            props.closeSearch();
          }}
          onReset={()=>{
            props.leaveQueue();
            props.closeSearch();
          }}
        >
          {({ submitForm, isSubmitting}) => (
            <Backdrop open={props.isOpen} className={classes.backdrop}>
              <Paper className={classes.paper} >
                <Form className={classes.form}>
                  <Box>
                    <Box m={1}>
                      <Field className={classes.field} component={TextField} name="username" type="string" 
                              label="Username" variant="outlined" size="small" autoCapitalize="words"/>
                    </Box>
                    <Box m={1}>
                      {!isSubmitting &&
                        <Button variant="contained" color="primary" size="small"
                            disabled={props.matchMakerState.isLoading} onClick={submitForm}>
                          ASK TO PLAY
                        </Button>
                      }
                      <Button  color="secondary" size="small" type="reset" >
                        CANCEL
                      </Button>
                    </Box>
                  </Box>
                </Form>
              </Paper>
            </Backdrop>
          )}
        </Formik> 
      </ExpansionPanelDetails>
    </div>
  );
}