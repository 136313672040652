import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
  Box,
  Card,
  LinearProgress} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faAngleDown
} from '@fortawesome/free-solid-svg-icons'

import FriendPanelContainer from './friend-panel';
import FriendGameStatsContainer from './friend-game-stats'
import GameLevelContainer from './game-level';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  statusHeading:{
    width: '100%',
    borderWidth:'2px',
    paddingTop:theme.spacing(0.5),
    paddingBottom:theme.spacing(0.5),
    marginBottom:theme.spacing(2)
  },
  gameLevel:{
    marginBottom:theme.spacing(2),
  },
  detail:{
    padding:theme.spacing(1),
  },
  progress:{
    margin:theme.spacing(0),
    padding:theme.spacing(0),
  },
  loading:{
    paddingBottom:theme.spacing(0),
  },
  loadingStatusText:{
    opacity:0.6,
  },
  button:{
    width:'100%'
  }
}));

const mapMatchMakerStatus = (status) => {
  if(status === 'INIT')
    return "CONNECTING...";

  if(status === 'WAITING')
    return "SELECT YOUR GAME TYPE";

  if(status === 'WAITING_FOR_FRIEND')
    return "WAITING FOR YOUR FRIEND";

  if(status === 'WAITING_FOR_OPPONENT')
    return "SEARCHING FOR AN OPPONENT";

  if(status === 'A_FRIEND_WANTS_TO_PLAY')
    return "A FRIEND WANTS TO PLAY";
}

export default function ControlledAccordions(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('practice-panel');

  const handleChange = (panel) => (event, isExpanded) => {
    props.logAccordianSelection(panel)
    setExpanded(isExpanded ? panel : false);
  };

  var headingClass = classes.statusHeading;

  if(props.matchMakerState.isLoading ){
    headingClass += (' ' + classes.loading);
  }

  return (
    <div className={classes.root}>
      <Card className={headingClass} variant="outlined">
        <Typography component="div">
          <Box textAlign="center" className={props.matchMakerState.isLoading ? classes.loadingStatusText : "" }
            fontWeight="fontWeightBold" fontSize="h6.fontSize">
            {mapMatchMakerStatus(props.matchMakerState.status)}
          </Box>
        </Typography>
        {props.matchMakerState.isLoading > 0 &&
          <LinearProgress display="none" color="secondary" className={classes.progress}/>
        }
      </Card>
      <Card className={classes.gameLevel}>
        <GameLevelContainer />
      </Card>

      <ExpansionPanel expanded={expanded === 'friend-panel'} onChange={handleChange('friend-panel')}>
        <ExpansionPanelSummary
            expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header">
          <Typography component="div" className={classes.heading} fontSize="h6.fontSize">
            <Box fontSize="subtitle1.fontSize">
              PLAY WITH A FRIEND
            </Box>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.detail}>
          <FriendGameStatsContainer />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'practice-panel'} onChange={handleChange('practice-panel')}>
        <ExpansionPanelSummary
            expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header">
            <Typography component="div" className={classes.heading} fontSize="h6.fontSize">
              <Box fontSize="subtitle1.fontSize">
              PRACTICE GAME
              </Box>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.detail}>
            <Typography variant="body2">
              Practice games are perfect for beginners. You will be paired with another random sutwoku player around your level. Dont worry, your result here wont affect your ranking!
            </Typography>
          </ExpansionPanelDetails>
          <ExpansionPanelDetails className={classes.detail}>
          {!props.matchMakerState.isLoading &&
              <Button variant='contained' color='primary' size='small' className={classes.button} disabled={props.matchMakerState.isLoading}
                  onClick={()=>props.joinRandomGame('PRACTICE')}>
                Play A Practice Game
              </Button>
          }
          {props.matchMakerState.isLoading &&
            <Button className={classes.homeButton} variant='outlined' color='primary' size='small'  className={classes.button} 
            onClick={() => props.leaveQueue()}>Leave Queue</Button>
          }

           
          </ExpansionPanelDetails>
        </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'rated-panel'} onChange={handleChange('rated-panel')}>
        <ExpansionPanelSummary
          expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header">
          <Typography component="div" className={classes.heading} fontSize="h6.fontSize">
            <Box fontSize="subtitle1.fontSize">
              RATED GAME
            </Box>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.detail}>
          <Typography variant="body2">
            Play a rated game against a random opponent. Win and your rating will go up, Lose... and i'm afraid it will go the opposite direction. Keeping playing and see if you can get to the top of Sutwoku's Rankings!
          </Typography>
        </ExpansionPanelDetails>
        <ExpansionPanelDetails className={classes.detail}>
          {!props.matchMakerState.isLoading &&
            <Button variant='contained' color='primary' size='small' className={classes.button} 
              disabled={props.matchMakerState.isLoading}
              onClick={()=>props.joinRandomGame('RANKED')}>
              Play A Rated Game
            </Button>
          }
          {props.matchMakerState.isLoading &&
            <Button className={classes.homeButton} variant='contained' color='primary' size='small' className={classes.button} 
            onClick={() => props.leaveQueue()}>Leave Queue</Button>
          }
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}