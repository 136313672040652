import { connect } from 'react-redux'
import SignInComponent from '../../components/account/signin/sign-in'

import {getAccount, signIn} from '../../actions/account'


const mapStateToProps = state => {
  return {
    account:state.account
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAccount:() => dispatch(getAccount()),
    signIn:(account) => (dispatch(signIn(account)))
  }
}
 
const SignInContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInComponent)
 
export default SignInContainer