import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  paper:{
    height:'100%'
  },
  content:{
    height:'100%',
    width:'100%', 
    display: 'flex'
  }, 
  text:{  
    margin: 'auto'
  },
  focus:{
    borderColor:theme.palette.primary.main,
    borderWidth: '2px',
    borderStyle: 'solid'
  },
  disabled:{
    opacity: 0.3
  },
  default:{ 
  }
}));
export default function Button(props) {
  const classes = useStyles();

  var buttonClick = props.doButtonClick;
  var style = classes.default;

  if(props.isComplete){
    style = classes.disabled
    buttonClick = () => {};
  }
  else if(props.buttonFocus && props.value == props.buttonFocus.number){
    style = classes.focus;
  }



  

  return (
    <Box width='27.33%' height="100%" p='0' m='3%' display="inline-block">
      <Paper className={`${classes.paper} ${style}`} onClick={() => buttonClick(props.value)} elevation={3}>
        <Typography className={classes.content} component="div">
          <Box fontWeight="fontWeightMedium" className={classes.text}>
            {props.value}
          </Box>
        </Typography>
      </Paper>
    </Box>  
  );
}