import { connect } from 'react-redux'
import GameOverComponent from '../components/gameover/gameover'

import {cleanUpGame} from '../actions/game'
import {loadGameOver, recordSocialClick} from '../actions/gameover'
import {loadSettings} from './../actions/settings'

const mapStateToProps = state => {
  return {
    data:state.gameover
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    cleanUpGame:() => dispatch(cleanUpGame()),
    loadGameOver:() => dispatch(loadGameOver()),
    loadSettings:() => dispatch(loadSettings()),
    recordSocialClick:(socialType) => dispatch(recordSocialClick(socialType)),
  }
}
 
const GameOverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GameOverComponent)
 
export default GameOverContainer