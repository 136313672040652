import MessageType from './match-making-message-type';

export class MatchMakingMessageHandlerFactory {
  constructor(defaultMessageHandler, 
    friendWaitingMessageHandler,
    gameFoundMessageHandler,
    friendRejectedGameMessageHandler) {
    this.defaultMessageHandler = defaultMessageHandler;
    this.friendWaitingMessageHandler = friendWaitingMessageHandler;
    this.gameFoundMessageHandler = gameFoundMessageHandler
    this.friendRejectedGameMessageHandler = friendRejectedGameMessageHandler
  }
  createMessageHandler(messageType){
    switch(messageType){
      case MessageType.friendWaiting:
        return this.friendWaitingMessageHandler;
      case MessageType.gameFound:
          return this.gameFoundMessageHandler;
      case MessageType.friendRejectedGame:
        return this.friendRejectedGameMessageHandler;
      default:
        return this.defaultMessageHandler;
    }
  }
}