class TileFactory {
  createTile(tileValue, playerNumber, shouldAnimate){
    if(tileValue === 0 && !playerNumber){
      return {
        value:0,
        style:'empty'
      }
    }
    else if(tileValue !== 0 && !playerNumber){
      return {
        value:tileValue,
        style:'default'
      }
    }
    else if(tileValue === 0 && playerNumber){
      return {
        value:0,
        style:'focus'
      }
    }
    else{
      var tile = {
        value:tileValue,
        style:'player-' + playerNumber
      }
      
      return tile;
    }
  }
}

export let tileFactory = new TileFactory();

