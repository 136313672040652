
import AppConfig from '../../config'

const LogLevel = Object.freeze({
  Trace: 0,
  Debug: 1,
  Info: 2,
  Warn: 3,
  Error: 4
});

// AI values
// export enum SeverityLevel
//     {
//         Verbose = 0,
//         Information = 1,
//         Warning = 2,
//         Error = 3,
//         Critical = 4,
//     }


const LogLevelEnabled = LogLevel.Verbose;

class Logger {
  constructor(aiLogger){
    this.aiLogger = aiLogger;
  }
  logMessage(level, message){
    if(level < LogLevelEnabled)
      return;

    switch(level){
      case LogLevel.Trace:
        aiLogger.log("TRACE : " + message);
        break;
      case LogLevel.Debug:
        this.aiLogger.trackTrace({
          message: message,
          severityLevel:0
        });

        if(AppConfig.consoleLoggingEnabled)
          console.log(`DEBUG : ${message}`);

        break;
      case LogLevel.Info:
        this.aiLogger.trackTrace({
          message: message,
          severityLevel:1
        });

        if(AppConfig.consoleLoggingEnabled)
          console.log(`INFO : ${message}`);

        break;
      case LogLevel.Warn:
        this.aiLogger.trackTrace({
          message: message,
          severityLevel:2
        });

        if(AppConfig.consoleLoggingEnabled)
          console.warn("WARN : " + message);

        break;
      case LogLevel.Error:
        this.aiLogger.trackTrace({
          message: message,
          severityLevel:3
        });
        
        if(AppConfig.consoleLoggingEnabled)
          console.error("ERROR : " + message);

        break;
    }
  }
  logApiMessage(apiMessage){
    var response = JSON.parse(apiMessage);

    var status = response.status;

    var message = `ApiResponse - Status : ${status}, Message : ${response.message}`;

    var level = LogLevel.Debug;

    if(status >= 500)
      level = LogLevel.Error;
    else if(status >= 400)
      level = LogLevel.Warn;

    this.logMessage(level, message);
  }
}

export {
  Logger,
  LogLevel
}