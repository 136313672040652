// Import Rollout SDK
import Rox from 'rox-browser';
import {LogLevel} from './services/logging/logger'

// Create a Roxflag in the flags container class
export var flags = {
  askForRatings: new Rox.Flag(false),
};

export async function initRollout(logger) {
  const options = {}

  // Register the flags with Rollout
  Rox.register('', flags);

  // Setup the Rollout key
  await Rox.setup('60157246f3bec1128d17ed3b', options);
  logger.logMessage(LogLevel.Info, 'Feature Flags Loaded');
}