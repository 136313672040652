import React from 'react';
import { connect } from 'react-redux'

import {
  Backdrop,
  Paper,
  Box,
  Avatar
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backdropFilter: 'blur(2px)'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    maxWidth:"80%"
  },
  image: {
    borderRadius:theme.spacing(1),
    width:'25%',
    height:'auto',
    float:'left',
    marginRight: theme.spacing(1),
  },
}));

function About(props) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(props.isOpen);
  };


  const aboutText = "Hi there! My name is Clinton Sweetnam and I am the Developer, QA, Marketer and Financier <br />" +
    "Hi there! My name is Clinton Sweetnam and I am the Developer, Tester, Markterer and "

  return (
    <Backdrop
      className={classes.backdrop}
      open={props.isOpen}
      onClick={() => props.close()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
        <Paper className={classes.paper}>
          <Box display="flex" flexWrap="wrap" alignItems="flex-start">
            <Box p={1} width={1} bgcolor="background.paper">
              <img src="http://localhost:8090/images/me.jpg" 
                className={classes.image}
              />
              Hi there! My name is Clinton Sweetnam and I am the Developer, QA, Marketer and Financier.  <br />
              Sutwoku is a game I have built on and off and on and off and on and off over the last 5 years
            </Box>
          </Box>
        </Paper>
    </Backdrop>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen:ownProps.isOpen
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    close:ownProps.close
  }
}
 
const AboutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(About)
 
export default AboutContainer