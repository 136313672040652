import {LogLevel} from './../../logging/logger'

import {handleGameFound} from './../../../actions/match-maker'

export class GameFoundMessageHandler {
  constructor(logger) {
    this.logger = logger;
  }
  handleMessage(message, dispatch){
    this.logger.logMessage(LogLevel.Debug, 'Handling game found message, Message : ' + JSON.stringify(message));
    dispatch(handleGameFound(message.gameRef, message.gameType, message.gameLevel, message.isBotGame));
    return Promise.resolve();
  }
}

