import React from "react";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import {Container, 
  Slide,
  Grid,
  Button} from '@material-ui/core';

import ControlledAccordions from './accordian/controlled-accordian'
import FriendRejectedGameContainer from './friend-rejected-game-container'
import FriendWaitingContainer from './friend-waiting-container';
import SutwokuMenuContainer from './../home/menu/sutwoku-menu'

const styles = theme => ({
  root: {
    minHeight:"100vh",
    position: 'relative'
  },
  item: {
    width: '95%',
    margin: '1%'
  },
  homeButton: {
    width: '100%',
  },
  card: {
    width: '100%',
    margin: theme.spacing(1),
  }
});

class MatchMakerComponent extends React.Component {
  constructor(){
    super();
    this.state ={
      show:true
    }
  }
  componentDidMount(){
    this.props.loadSettings();
    this.props.initState();
    this.props.getAccount()
      .then(() => {
        this.props.getFriendGameStats();
        this.props.connectToServer();
      })

  }
  onExited(){
    this.props.initState();
    this.props.history.push(this.state.target)
  }
  componentWillUnmount(){
    this.props.disconnectFromServer();
  }
  beginExit(target){
    this.setState({
      target,
      show:!this.state.show
    });
  };
  render() {
    const { classes } = this.props;
    if(this.props.matchMaker.status === 'GAME_FOUND' &&
        !this.state.target){
      this.beginExit('/game')
    }
    
    return(
      <Slide direction="left" className={classes.root} onExited={this.onExited.bind(this)} in={this.state.show} mountOnEnter unmountOnExit>
        <Container id='match-maker-container' maxWidth="lg">
          <SutwokuMenuContainer className={classes.root} className={classes.menu} beginExit={this.beginExit.bind(this)} />
          <Grid className={classes.root} container 
              direction="column" justify="center" alignItems="center">
            <Grid item className={classes.item}>
              <ControlledAccordions matchMakerState={this.props.matchMaker}
              initFriendGame={this.props.initFriendGame}
              joinRandomGame={this.props.joinRandomGame}
              leaveQueue={this.props.leaveQueue}
              logAccordianSelection={this.props.logAccordianSelection}
              beginExit={this.beginExit.bind(this)}/>
            </Grid>
            <Grid item className={classes.item}>
              <Button className={classes.homeButton} variant='contained' color='secondary' size='small'
              disabled={(this.props.matchMaker && this.props.matchMaker.isLoading)} 
              onClick={() => this.beginExit('/home')}>Home</Button>
            </Grid>
          </Grid>
        <FriendRejectedGameContainer />
        <FriendWaitingContainer beginExit={this.beginExit}/>
        </Container>
      </Slide>
    )
  }
}

MatchMakerComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MatchMakerComponent);