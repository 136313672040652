import {SET_TILE_FOCUS, SET_BUTTON_FOCUS, REMOVE_FOCUS, SET_CONTROL_STATUS} from './../actions/control'

const control = (state, action) => {
  
  if(!state)
    state = {tileFocus:null, buttonFocus:null, status:'IDLE'};

  switch(action.type){
    case SET_TILE_FOCUS:
      if(state.tileFocus && 
            state.tileFocus.col === action.col &&
            state.tileFocus.row === action.row){
        state = Object.assign({}, state, {tileFocus:null});
      }else{
        state = Object.assign({}, state, {tileFocus:{col:action.col, row:action.row}});
      }

      state = Object.assign({}, state, {buttonFocus:null});
      return state;
    case SET_BUTTON_FOCUS:
      state = Object.assign({}, state, {buttonFocus:{number:action.number}})
      state = Object.assign({}, state, {tileFocus:null});
      return state;
    case SET_CONTROL_STATUS:
      return Object.assign({}, state, {status:action.status});
    case REMOVE_FOCUS:
      return {tileFocus:null, buttonFocus:null, status:'IDLE'}
    default:
      return state;
  }
};

export default control;