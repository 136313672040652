class AppConfig {
  constructor() {
    this.isWebVersion = true;

    if (process.env.NODE_ENV !== 'production') {
      this.contentBaseUrl= 'http://localhost:8090/';
      this.apiBaseUrl = 'http://localhost:8090/api/';
      this.gameWsBaseUrl = 'ws://localhost:8091/websockets/';
      this.matchMakerWsBaseUrl = 'ws://localhost:8092/websockets/';
      this.consoleLoggingEnabled = true;
      this.aiKey = 'abc';
    }else{
      this.contentBaseUrl= 'https://sutwoku-dev-api.azurewebsites.net/';
      this.apiBaseUrl = 'https://sutwoku-dev-api.azurewebsites.net/api/';
      this.gameWsBaseUrl = 'wss://sutwoku-dev-socket-app.azurewebsites.net/websockets/';
      this.matchMakerWsBaseUrl = 'wss://sutwoku-dev-match-maker.azurewebsites.net/websockets/';
      this.consoleLoggingEnabled = false;
      this.aiKey = '555fe25f-6f81-4eb4-b568-e932c948c136';
    }
  }
}

export default new AppConfig();