import {LogLevel} from './../../logging/logger'
import {setPuzzle} from './../../../actions/puzzle'
import {beginStartGame} from './../../../actions/game';

export class StartGameMessageHandler {
  constructor(logger, storage) {
    this.logger = logger;
    this.storage = storage;
  }
  handleMessage(message, dispatch){
    this.logger.logMessage(LogLevel.Debug, 'Starting Game via StartGameMessageHandler, Message : ' + JSON.stringify(message));
    dispatch(setPuzzle(message.puzzle));
    dispatch(beginStartGame(message.countdownSeconds, message.isAgainstBot, message.gameId));
    return Promise.resolve();
  }
}
