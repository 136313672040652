import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Avatar,
  Typography
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faUser
} from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles((theme) => ({
  avatar:{
    backgroundColor: theme.palette.primary.main
  }
}));
export default function Account(props) {
  const classes = useStyles();
  const [count, setCount] = useState(0);
  return (
    <Box display="flex" alignItems="center" justifyContent="center" width='100%'>
      <Box> 
        <Avatar className={classes.avatar}>
          <FontAwesomeIcon icon={faUser} />
        </Avatar>
      </Box>
      <Box height='100%'>
        <Typography variant="h4" component="div">
          <Box fontWeight="fontWeightBold" marginLeft={2} >
            {props.account ? props.account.name : 'loading...'}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
}