import React from 'react';
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';

import {rejectFriendWaiting, acceptFriendWaiting} from './../../actions/match-maker'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  accept:{
    color:theme.palette.success.main
  },
  reject:{
    color:theme.palette.error.light
  },
}));

function FriendWaiting(props) {
  const classes = useStyles();

  const handleAccept = () => {
    props.acceptFriendWaiting();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={props.matchMaker.showFriendWaiting}
        autoHideDuration={10000}
        onClose={() => props.rejectFriendWaiting()}
        message={`${props.matchMaker.friendWaiting} wants to play`}
        action={
          <React.Fragment>
            <Button className={classes.accept} size="small" onClick={handleAccept}>
              ACCEPT
            </Button>
            <Button className={classes.reject}  size="small" onClick={() => props.rejectFriendWaiting()}>
              REJECT
            </Button>
          </React.Fragment>
        }
      />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    matchMaker:state.matchMaker
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    rejectFriendWaiting:() => dispatch(rejectFriendWaiting()),
    acceptFriendWaiting:() => dispatch(acceptFriendWaiting()),
  }
}
 
const FriendWaitingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FriendWaiting)
 
export default FriendWaitingContainer