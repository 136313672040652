import { connect } from 'react-redux'
import HomeComponent from './../components/home/home'

import {getAccount, unregister} from './../actions/account'
import {loadSettings} from './../actions/settings'
import {getStats, changeDifficulty} from './../actions/stats'
import {cleanUpGame} from './../actions/game'
import {loadModalSettings} from './../actions/modals'



const mapStateToProps = state => {
  return {
    account:state.account,
    stats:state.stats,
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    cleanUpGame:() => dispatch(cleanUpGame()),
    getAccount:(refresh) => dispatch(getAccount(refresh)),
    unregister:() => dispatch(unregister()),
    loadSettings:() => dispatch(loadSettings()),
    getStats:(account) => dispatch(getStats(account)),
    changeDifficulty:(difficulty) => dispatch(changeDifficulty(difficulty)),
    loadModalSettings:() => dispatch(loadModalSettings())
  }
}
 
const HomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeComponent)
 
export default HomeContainer