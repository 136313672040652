import {SET_GAMEOVER, CLEAR_GAMEOVER} from './../actions/gameover'

var createStateFromData = (game, user, opponent) => {
  var you;
  var them;

  if(game.players[0].accountId === user.id){
    you = game.players[0];
    them = game.players[1];
  }
  else{
    you = game.players[1];
    them = game.players[0];
  }

  you.name = user.name
  them.name = opponent.name

  if(you.score > them.score){
    you.result = 'winner';
    them.result = 'loser';
  }
  else if(you.score > them.score){
    you.result = 'loser';
    them.result = 'winner';
  }
  else{
    you.result = 'draw';
    them.result = 'draw';
  }

  return {
    id:game.gameId,
    you,
    them,
    gameType:game.gameType
  };
}

const gameover = (state, action) => {
 if(!state)
   state = {};
   
 switch(action.type){
  case SET_GAMEOVER:
    state = createStateFromData(action.game, action.user, action.opponent);
    return state;
  case CLEAR_GAMEOVER:
    state = {}
    return state;
  default:
    return state;
 }
};

export default gameover;