import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import {
  Button, 
  Snackbar} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import CircularProgress from '@material-ui/core/CircularProgress';

import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
  field:{
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(1),
    width:'100%'
  },
  buttons:{
    margin:theme.spacing(0.5),
  },
  default:{

  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

export default function SignInForm(props) {
  const classes = useStyles();
  return (
    <Formik
      className={classes.form}
      initialValues={{
        username: '',
      }}
      validate={values => {
        const errors = {};
        if (!values.username) {
          errors.username = 'Required';
        } else if (values.username.length < 3) {
          errors.username = 'Minimum username length is 3 characters';
        } else if (values.username.length > 12) {
          errors.username = 'Maximum username length is 12 characters';
        }

        if (!values.password) {
          errors.password = 'Required';
        }  else if (values.password.length < 6) {
          errors.password = 'Minimum password length is 3 characters';
        } 
        return errors;
      }}
      onSubmit={(values, {setSubmitting, setErrors, setStatus}) => {
        var account = { "name":values.username, "password":values.password, guest:false}
        props.signIn(account)
          .then(() => {
            setSubmitting(false);
            props.exit('/home')
          })
          .catch((err) => {
            setSubmitting(false);
            if(err.errors)
              setErrors({username:err.errors[0]});
            else
              setStatus({success:false});
          })
      }}
    >
      {({ submitForm, isSubmitting, status, setStatus }) => (
        <Form>
          <Field className={classes.field} component={TextField} name="username" type="string" 
                  label="Username" variant="outlined" size="small" autoFocus autoCapitalize="words"/>
          <Field  className={classes.field} component={TextField} name="password" type="password" 
                  label="Password" variant="outlined" size="small"/>
          <Button size="small" variant="contained" color="primary" disabled={isSubmitting} onClick={submitForm}
              className={classes.buttons}>
            Sign In
            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
          <Button size="small" variant="contained" color="secondary" 
            onClick={() => props.exit('/')} className={classes.buttons}>
            Back
          </Button>
          <Snackbar autoHideDuration={5000} 
            open={status != undefined && !status.success} 
            onClose={() => setStatus({success:true})}>
            <Alert severity="error" onClose={() => setStatus({success:true})}>
              Oppps... This is embarrasing. Something unexpected went wrong! Please try again.
            </Alert>
          </Snackbar>
        </Form>
      )}
    </Formik> 
  );
}