import {LogLevel} from './../services/logging/logger'

export const SET_MODAL_SETTINGS = 'SET_MODAL_SETTINGS';
export const SET_MOBILE_MODAL_SHOWN = 'SET_MOBILE_MODAL_SHOWN';
export const SET_SHOW_RATINGS_MODAL_SHOWN = 'SET_SHOW_RATINGS_MODAL_SHOWN';

export const loadModalSettings = () => {
  return(dispatch, getState, services) => {
    services.storage.get('modalSettings')
        .then(modalSettings => {
          if(modalSettings){
            dispatch(setModalSettings(modalSettings))
          }
          else{
            services.storage.save('modalSettings', {
              ratingModal:{
                hasShown:false
              }
            });
          }
        })
    
  };
};

export const setShownRatingModal = () => {
  return(dispatch, getState, services) => {
    services.logger.logMessage(LogLevel.Info, 'Rating Request Shown')
    services.storage.get('modalSettings')
      .then(modalSettings => {
        var ratingModal = {
          hasShown:true,
          shownAt:new Date()
        };

        modalSettings.ratingModal=ratingModal;
        services.storage.save('modalSettings', modalSettings);
        dispatch(setShownRatingModalShown())
      })
    
  };
};

export const setModalSettings = (modalSettings) => {
  return {
    type:SET_MODAL_SETTINGS,
    modalSettings
  }
};

export const setShownRatingModalShown = () => {
  return {
    type:SET_SHOW_RATINGS_MODAL_SHOWN
  }
};

export const setMobileModalShown = () => {
  return {
    type:SET_MOBILE_MODAL_SHOWN
  }
};
