import React, { useState } from 'react';
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  Divider,
  LinearProgress,
  Typography,
  Button
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faSearch
} from '@fortawesome/free-solid-svg-icons'

import {initFriendGame, leaveQueue} from './../../../actions/match-maker'
import FriendPanelContainer from './friend-panel';

const calculatePercentages = (totalCount, resultCount) => {
  const minWidth=10;
  const maxWidth=80;

  if(!resultCount)
    return minWidth;

  var percentage = (resultCount * 100.00)/totalCount;

  if(percentage <= minWidth){
    return minWidth;
  }
  if(percentage >= maxWidth){
    return maxWidth;
  }

  return percentage;
}

const useStyles = makeStyles((theme) => ({
  root:{
    minWidth: '100%',
  },
  friendRow:{
    marginTop:theme.spacing(1),
  },
  win:{
    color:theme.palette.success.contrastText,
    backgroundColor:theme.palette.success.main,
    borderTopLeftRadius:theme.shape.borderRadius,
    borderBottomLeftRadius:theme.shape.borderRadius
  },
  draw:{
    color:theme.palette.warning.contrastText,
    backgroundColor:theme.palette.warning.light
  },
  loss:{
    color:theme.palette.error.contrastText,
    backgroundColor:theme.palette.error.main,
    borderTopRightRadius:theme.shape.borderRadius,
    borderBottomRightRadius:theme.shape.borderRadius
  },
  disabledPlayButton:{
    color:'rgba(255, 255, 255, 0.3)'
  },
  playButton:{
    color:theme.palette.primary.light,
  },
  cancelButton:{
    color:theme.palette.secondary.light,
  },
  searchButton:{
    color:theme.palette.primary.light,
  },
  button:{
    padding:'0px'
  },
  loading:{
    width:'100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    padding:theme.spacing(2),
  }
}));

function FriendGameStats(props) {
  const classes = useStyles();

  const [name, setFriendName] = useState("");
  const [showSearch, openSearch] = useState(false);

  const selectFriend = (friendName) => {
    if(!props.isLoading){
      setFriendName(friendName)
      props.initFriendGame(friendName)
    }
  }

  const cancelFriendGame = () => {
    setFriendName("")
    props.leaveQueue()
  }
  
  return (
      <div className={classes.root}> 
        <Box display='flex' textAlign='center' mx={1} className={classes.friendRowHeader} >
          <Box textAlign="left" width='25%' fontWeight="fontWeightBold">
            <Typography component="div" variant="body2">
              <Box textAlign="left" m={0} width='25%' fontWeight="fontWeightBold">
                Friends
              </Box>
            </Typography>
          </Box>
          <Box textAlign="left" width='25%' fontWeight="fontWeightBold">
          </Box>
          {!props.isLoading &&
            <Box textAlign="right" width='50%' fontWeight="fontWeightBold" className={classes.searchButton}
              onClick={() => {openSearch(true)}}>
              <Button className={classes.button} color='primary'><FontAwesomeIcon icon={faSearch} />SEARCH</Button>
            </Box>
          }
          {props.isLoading &&
            <Box textAlign="right" width='50%' fontWeight="fontWeightBold" className={classes.cancelButton}
              onClick={() => {cancelFriendGame()}}>
              <Button className={classes.button} color='primary'>CANCEL</Button>
            </Box>
          }
         
        </Box>
        <Divider />
        {!props.friendGameStats &&
          <Box w='100%' display='flex' justifyContent="center">
            <Box className={classes.loading} >
              <LinearProgress />
            </Box>
          </Box>
        }
        {(props.friendGameStats && props.friendGameStats.length) === 0 &&
          <Box w='100%' display='flex' justifyContent="center">
            <Box className={classes.loading} >
              <Typography component="div" variant="body2">
                <Box textAlign="left" m={0.5}>
                  You have not played any friend games yet. Once you do your friends will appear here.
                </Box>
              </Typography>
            </Box>
          </Box>
        }
        {(props.friendGameStats && props.friendGameStats.length) > 0 &&
          props.friendGameStats.map((row) => (
            <Box key={row.name} className={classes.friendRow} display='flex' textAlign='center' alignItems="center">
              <Box width='30%' >
                {row.name} 
              </Box>
              <Box width='50%' display='flex' m={0}>
                <Box width={`${calculatePercentages(row.totalCount, row.winCount)}%`} className={classes.win} >
                  {row.winCount}
                </Box>
                <Box width={`${calculatePercentages(row.totalCount, row.drawCount)}%`} className={classes.draw} >
                  {row.drawCount}
                </Box>
                <Box width={`${calculatePercentages(row.totalCount, row.lossCount)}%`} className={classes.loss} >
                  {row.lossCount}
                </Box>
              </Box>
              {props.isLoading &&
                <Box width='20%' color="primary" className={classes.disabledPlayButton} fontWeight="fontWeightBold"
                    onClick={() => {}} disabled >
                  <Button className={classes.button} disabled>PLAY</Button>
                </Box>
              }
              {!props.isLoading &&
                <Box width='20%' color="primary" className={classes.playButton} fontWeight="fontWeightBold"
                    onClick={() => selectFriend(row.name)} >
                  <Button className={classes.button} color='primary'>PLAY</Button>
                </Box>
              }
            </Box>
          ))
        }
        <FriendPanelContainer initFriendGame={props.initFriendGame} 
          matchMakerState={props.matchMakerState}
          leaveQueue={props.leaveQueue}
          isOpen={showSearch}
          closeSearch={() => openSearch(false)}/>
      </div>
  );
}

const mapStateToProps = state => {
  return {
    friendGameStats: state.matchMaker.friendGameStats,
    isLoading: state.matchMaker && state.matchMaker.isLoading,
    matchMakerState: state.matchMaker,
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    initFriendGame:(friendName) => dispatch(initFriendGame(friendName)),
    leaveQueue:() => dispatch(leaveQueue()),
  }
}
 
const FriendGameStatsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FriendGameStats)
 
export default FriendGameStatsContainer