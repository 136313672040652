import React from 'react';
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';

import {dismissFriendRejectedGame} from './../../actions/match-maker'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  accept:{
    color:theme.palette.success.main
  },
  reject:{
    color:theme.palette.error.light
  },
}));

function FriendRejectedGame(props) {
  const classes = useStyles();

  const handleDismiss = () => {
    props.dismissFriendRejectedGame();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={props.matchMaker.showFriendRejectedGame}
        autoHideDuration={10000}
        onClose={() => props.rejectFriendWaiting()}
        message={`${props.matchMaker.rejectedFriendName} rejected you game request`}
        action={
          <React.Fragment>
            <Button className={classes.accept} size="small" onClick={handleDismiss}>
              OK
            </Button>
          </React.Fragment>
        }
      />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    matchMaker:state.matchMaker
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dismissFriendRejectedGame:() => dispatch(dismissFriendRejectedGame()),
  }
}
 
const FriendRejectedGameContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FriendRejectedGame)
 
export default FriendRejectedGameContainer