import React from 'react';
import { connect } from 'react-redux'

import {
  Backdrop,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import {setShownRatingModal} from './../../../actions/modals'
import {flags} from './../../../Flags'

const GAME_THRESHOLD = 10;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    maxWidth:"80%"
  },
  text:{
    margin:theme.spacing(1)

  }
}));

function RatingRequest(props) {
  const classes = useStyles();

  const handleClose = () => {
    props.setShownRatingModal();
  };

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      return "unknown";
  }

  const getLink = () => {
    var deviceType = getMobileOperatingSystem();

    if(deviceType == 'Android')
      return 'market://details?id=com.vcvi.folkwhosudoku'
    else if (deviceType == 'iOS')
      return 'https://apps.apple.com/app/id1517689580'
    else 
      return 'https://www.sutwoku.com/'
  }

  return (
    <Backdrop
      className={classes.backdrop}
      open={props.shouldShow}
      onClick={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
        <Card className={classes.card}>
          <CardContent>
          <Typography variant="h4" className={classes.text}>
            Enjoying Sutwoku?
          </Typography>
          <Typography variant="body1" color="textSecondary" className={classes.text}>
            {`You seem to love playing Sutwoku. You have now played over ${props.noOfGames} games!`}
          </Typography>
          <Typography variant="body1" color="textSecondary" className={classes.text}>
            I love feedback. If you have any, I would appreciate leaving some on the store... and maybe even a 5-star rating.
          </Typography>
          </CardContent>
          <CardActions>
          <Button size="small" color="primary" variant="contained" href={getLink()}>
            Would Love To
          </Button>
          <Button size="small" color="secondary" onClick={handleClose}>
            Not Right Now
          </Button>
          </CardActions>
        </Card>
    </Backdrop>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    shouldShow: state.modals?.ratingModal?.hasShown == false && 
      state.stats?.gameStats?.noOfGames >= GAME_THRESHOLD &&
      flags.askForRatings.isEnabled(),
    noOfGames:state.stats?.gameStats?.noOfGames
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setShownRatingModal:() => dispatch(setShownRatingModal())
  }
}
 
const RatingRequestContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RatingRequest)
 
export default RatingRequestContainer