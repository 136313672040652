import {setControlStatus, resetControls} from './control'
import {setCorrectTile, clearPuzzle} from './puzzle';
import {clearGameover} from './gameover';

export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_STATUS = 'SET_STATUS';
export const SET_TYPE = 'SET_TYPE';
export const FINISH_GAME = 'FINISH_GAME';
export const DO_GUESS = 'DO_GUESS';
export const SET_SCORE = 'SET_SCORE';
export const CLEAN_UP_GAME = 'CLEAN_UP_GAME';
export const SET_GAME = 'SET_GAME';
export const SET_PLAYER_DETAILS = 'SET_PLAYER_DETAILS';
export const SET_OPPONENT_DETAILS = 'SET_OPPONENT_DETAILS';

export const connectToServer = (account) => {
  return(dispatch, getState, services) => {
    return  services.messageManager.openSocket(dispatch)
      .then(() => {
        services.storage.get('game')
          .then(game => {
            var relevantRank = account.ratings.filter(r => r.gameLevel == game.gameLevel)[0].rating;
            dispatch(setGame(game.gameRef, game.gameType, game.gameLevel));
            dispatch(setPlayerDetails(account.name, relevantRank));
            dispatch(setStatus('WAITING_FOR_PLAYER'));
            services.messageManager.sendConnectionMessage(getState().account, game.gameRef, game.gameType, game.gameLevel, game.isBotGame);
          });
      });
  };
};

export const disconnectFromServer = () => {
  return(dispatch, getState, services) => {
    return services.messageManager.disconnect();
  };
}

export const handleConnectionSuccess = () => {
  return(dispatch, getState, services) => {
    dispatch(setMessage(`Waiting for opponent`))
  };
};

export const handleDisconnectionSuccess = () => {
  return(dispatch, getState, services) => {
    if(getState().game.gameState === 'DISCONNECTED'){
      return;
    }

    dispatch(setStatus('DISCONNECTED'));
    dispatch(setMessage(`Oh Oh.. you have been disconnected. Trying to reconnect.`))
    var gameId = getState().game.gameRef
    var accountId = getState().account.id
    var interval = setInterval(() => {
      if(getState().game.gameState !== 'DISCONNECTED'){
        clearInterval(interval);
        return;
      }
      services.messageManager.attemptToReconnect(gameId, accountId, dispatch)
    }, 1000)
  };
};

export const handleReconnection = () => {
  return(dispatch, getState, services) => {
    if(getState().game.gameState != 'DISCONNECTED'){
      return;
    }

    dispatch(setStatus('IN_PROGRESS'));
  };
}

export const beginStartGame = (countdownSeconds, isAgainstBot) => {
  return(dispatch, getState, services) => {
    return new Promise((resolve, reject) => {
      if(getState().game.gameState == "WAITING_FOR_PLAYER" ||
            getState().game.gameState == "WAITING_FOR_FRIEND"){
        dispatch(setStatus('STARTING_GAME'));
        dispatch(setMessage(`Starting game in ${countdownSeconds--} secs`))
        var interval = setInterval(() => {
          if(countdownSeconds <= 0){
            clearInterval(interval);
            dispatch(startGame());

            if(isAgainstBot)
              services.bot.init(getState().game.gameRef);
            
            resolve();
          }
          else
            dispatch(setMessage(`Starting game in ${countdownSeconds--} secs`))

        }, 1000);
      }else{
        resolve();
      }
    });
  };
};

export const handleGuessResult = (guessResult) => {
  return(dispatch, getState, services) => {
    var account = getState().account;

    var isThisaccount = guessResult.userId === account.id;

    if(isThisaccount && guessResult.wasValidMove === false){
      dispatch(setControlStatus('IDLE'));
      return Promise.resolve();
    }

    if(guessResult.wasCorrect)
    {
      var playerNo = isThisaccount ? 1 : 2;

      dispatch(setCorrectTile(guessResult.column, 
        guessResult.row,
        guessResult.value,
        playerNo));

      if(isThisaccount){
        dispatch(setControlStatus('IDLE'));
        if(getState().settings.noises)
          services.contentCache.getAudio().rightGuessYou.play();
      }else{
        if(getState().settings.noises)
          services.contentCache.getAudio().rightGuessThem.play();
      }
    }
    else if(isThisaccount){
      dispatch(handleIncorrectGuess(5));
    }

    return Promise.resolve();
  }
}

export const handleIncorrectGuess = (blockedTime) => {
  return(dispatch, getState, services) => {
    return new Promise((resolve, reject) => {
      dispatch(setStatus('BLOCKED'));
      dispatch(setMessage(`Oh Oh... Wrong Guess! You lost a point and are blocked for ${blockedTime--} secs.`))
      if(getState().settings.noises)
        services.contentCache.getAudio().wrongGuess.play();

      var interval = setInterval(() => {
        if(blockedTime <= 0){
          clearInterval(interval);
          dispatch(setControlStatus('IDLE'));
          dispatch(startGame('BLOCKED'));
          resolve();
        }
        else
          dispatch(setMessage(`Oh Oh... Wrong Guess! You lost a point and are blocked for ${blockedTime--} secs.`))

      }, 1000);
    });
  }
}

export const finishGame = () => {
  return(dispatch, getState, services) => {
    return new Promise((resolve, reject) => {
      dispatch(setMessage(`Game Over`));
      dispatch(setStatus('FINISHED'));
      resolve();
    })
  }
}

export const cleanUpGame = () => {
  return(dispatch, getState, services) => {
    return new Promise((resolve, reject) => {
      services.bot.dispose();
      services.messageManager.disconnect();
      dispatch(clearPuzzle());
      dispatch(clearGameover())
      dispatch(resetControls())
      dispatch({
        type: CLEAN_UP_GAME
      })
      return resolve();
    });
  }
}

export const startGame = () => {
  return setStatus('IN_PROGRESS')
};

export const setMessage = (message) => {
  return {
    type: SET_MESSAGE,
    message,
  }
};

export const setGame = (gameRef, gameType, gameLevel) => {
  return {
    type: SET_GAME,
    gameRef,
    gameType,
    gameLevel
  }
};

export const setStatus = (status) => {
  return {
    type: SET_STATUS,
    status
  }
};

export const setPlayerDetails = (name, rank) => {
  return {
    type: SET_PLAYER_DETAILS,
    name,
    rank
  }
}

export const setOpponentDetails = (name, rank) => {
  return {
    type: SET_OPPONENT_DETAILS,
    name,
    rank
  }
}

export const setScore = (yourScore, yourName, theirScore, theirName) => {
  return {
    type: SET_SCORE,
    yourScore,
    theirScore,
    yourName,
    theirName
  }
}