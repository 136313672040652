import React from 'react';

import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Box,
  Menu,
  MenuItem,
  Switch,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faBars,
  faSun,
  faMoon,
  faUserPlus,
  faInfo
} from '@fortawesome/free-solid-svg-icons'

import {toggleThemeMode} from '../../../actions/settings'
import {unregister} from '../../../actions/account'
import SutwokuMenuSignOutContainer from '../menu/sign-out-menu-item'
import {flags} from './../../../Flags'

const useStyles = makeStyles((theme) => ({
  menuButton:{
    position: "absolute",
    top: theme.spacing(1),
    padding:'env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0px) env(safe-area-inset-left, 0px)',
    right: theme.spacing(1)
  },
  menuItem:{
    margin:'auto'
  },
  menuItemIcon:{
    marginRight:theme.spacing(1),
  }
}));

function SutwokuMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleTheme = () => {
    props.toggleTheme()
  };

  const openAboutMenu = () => {
    handleClose();
    props.openAbout();
  };

  return (
    <Box>
      <IconButton 
      className={classes.menuButton}
      aria-controls="simple-menu" 
      aria-haspopup="true" 
      onClick={handleClick}>
        <FontAwesomeIcon icon={faBars} size="lg"/>
      </IconButton>
      <Menu id="simple-menu" 
        anchorEl={anchorEl} 
        keepMounted 
        MenuListProps={{ style: { padding: 0 } }}
        open={Boolean(anchorEl)} 
        onClose={handleClose} >
        <MenuItem onClick={toggleTheme} className={classes.menuItem} >
          <FontAwesomeIcon icon={faSun} />
          <Switch
          checked={props.activeTheme === 'dark'}
          size='small'
          color="default" inputProps={{ 'aria-label': 'checkbox with default color' }}/>
          <FontAwesomeIcon icon={faMoon} />
        </MenuItem>
        {/* <MenuItem onClick={openAboutMenu}
          className={classes.menuItem} >
          <FontAwesomeIcon icon={faInfo} className={classes.menuItemIcon} fixedWidth/> About
        </MenuItem> */}
        {props.isGuest &&
          <MenuItem onClick={() => {props.beginExit('account/register')}} className={classes.menuItem} >
            <FontAwesomeIcon icon={faUserPlus} className={classes.menuItemIcon} fixedWidth/> Register
          </MenuItem>
        }
        <SutwokuMenuSignOutContainer className={classes.menuItem} beginExit={props.beginExit}/>
      </Menu>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    activeTheme:state.settings.themeType,
    isGuest:state.account.guest === true
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleTheme:() => dispatch(toggleThemeMode()),
    openAbout:ownProps.openAbout,
    unregister:() => dispatch(unregister()),
  }
}
 
const SutwokuMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SutwokuMenu)
 
export default SutwokuMenuContainer
