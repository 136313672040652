import React from "react";

import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import CardActions from '@material-ui/core/CardActions';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Typography,
  Link,
  Card,
  CardContent,
  Box,
  Container,
  Grid,
  Button,
  Snackbar
} from '@material-ui/core'

import MuiAlert from '@material-ui/lab/Alert';

const styles = theme => ({
  root: {
    height:'100%'
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
});


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class GuestComponent extends React.Component {
  constructor(){
    super();
    this.state ={
      success:false,
      target:'/home',
      show:true,
      inProgress:false,
      showError:false
    }
  }
  componentDidMount() {
    if(this.account == null)
      this.props.getAccount(true)
        .then(account => {
          if(account != null)
            this.props.history.push('/home')
        });
  }
  onExited(){
    this.props.history.push(this.state.target)
  }
  beginExit(target){
    this.setState({
      target,
      show:!this.state.show
    });
  };
  createGuestAccount(){
    this.setState({
      inProgress:true
    })
    
    this.props.createGuestAccount()
      .then(() => {
        this.setState({
          inProgress:false,
          success:true
        })
        setInterval(() => {
          this.beginExit('/home')
        }, 500)
      })
      .catch(reason => {
        this.setState({
          inProgress:false,
          showError:true
        })
      });
  }
  render() {
    const { classes } = this.props;

    return(
      <Container className={classes.root} id='guest-container' maxWidth="lg">
        <Grid className={classes.root} container justify="center" alignItems="center">
          <Slide direction="left" onExited={this.onExited.bind(this)} in={this.state.show} mountOnEnter unmountOnExit>
            <Grid item>
              <Card>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h3" component="h3" gutterBottom>
                    Sutwoku
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Welcome to Sutwoku, the two player sudoku game.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button variant="contained" size="small" color="primary" 
                    disabled={this.state.inProgress}
                    onClick={this.createGuestAccount.bind(this)}>
                    Play as Guest
                    {this.state.inProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button> 
                  <Button variant="contained" size="small" color="secondary" onClick={()=> this.beginExit('/account/register')}>
                    Register
                  </Button>
                </CardActions>
                <Box margin={1}>
                  <Typography variant="body2">
                    <Link href="#" onClick={()=> this.beginExit('/account/signin')}>
                      Already have an account?
                    </Link>
                  </Typography>
                </Box>
              </Card>
              <Snackbar autoHideDuration={5000} open={this.state.showError} onClose={() => this.setState({showError:false})}>
                <Alert severity="error" onClose={() => this.setState({showError:false})}>
                  Oppps... This is embarrasing. Something unexpected went wrong! Please try again.
                </Alert>
              </Snackbar>
            </Grid>
          </Slide>
        </Grid>
      </Container>
    )
  }
}

GuestComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GuestComponent);