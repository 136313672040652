import React from "react";
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Button from './button'
import SpecialButton from './special-button'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdjust,
  faVolumeUp,
  faVolumeMute,
  faEraser } from '@fortawesome/free-solid-svg-icons'

import {doButtonClick,
  resetControls} from './../../../actions/control'

import {toggleThemeMode, 
  toggleNoises} from './../../../actions/settings'

const useStyles = makeStyles((theme) => ({
  root:{
    height: 'calc((min(56vh, 100vw) - 32px)/9)',
    cursor: 'default'
  }
}));

function Buttons(props) {
  const classes = useStyles();
  const isNumberComplete = (number, puzzle) => {
    var count = 0;
    
    for(var row = 0;row<9;row++){
      for(var col = 0;col<9;col++){
        if(puzzle[row][col].value == number)
          count+=1;
      }
    }
  
    return count === 9;
  }

  return (
    <Box>
      <Box className={classes.root}>
        <Box width='33.33%' height="84%" display="inline-block">
          <SpecialButton doButtonClick={props.toggleThemeMode}>
            <FontAwesomeIcon icon={faAdjust} />
          </SpecialButton>
          <SpecialButton doButtonClick={props.resetControls}  text='RESET'>
            <FontAwesomeIcon icon={faEraser}/>
          </SpecialButton>
          <SpecialButton doButtonClick={props.toggleNoises}  text='RESET'>
            <FontAwesomeIcon icon={props.settings.noises ? faVolumeMute : faVolumeUp}/>
          </SpecialButton>
        </Box>
      </Box>
      <Box className={classes.root}>
        <Box width='33.33%' height="84%" display="inline-block">
          <Button value='1' doButtonClick={props.doButtonClick} 
            buttonFocus={props.buttonFocus} isComplete={isNumberComplete(1, props.puzzle)}/>
          <Button value='2' doButtonClick={props.doButtonClick} 
            buttonFocus={props.buttonFocus} isComplete={isNumberComplete(2, props.puzzle)}/>
          <Button value='3' doButtonClick={props.doButtonClick}
            buttonFocus={props.buttonFocus} isComplete={isNumberComplete(3, props.puzzle)}/>
        </Box>
        <Box width='33.33%' height="84%" display="inline-block">
          <Button value='4' doButtonClick={props.doButtonClick}
            buttonFocus={props.buttonFocus} isComplete={isNumberComplete(4, props.puzzle)}/>
          <Button value='5' doButtonClick={props.doButtonClick}
            buttonFocus={props.buttonFocus} isComplete={isNumberComplete(5, props.puzzle)}/>
          <Button value='6' doButtonClick={props.doButtonClick}
            buttonFocus={props.buttonFocus} isComplete={isNumberComplete(6, props.puzzle)}/>
        </Box>
        <Box width='33.33%' height="84%" display="inline-block">
          <Button value='7' doButtonClick={props.doButtonClick}
            buttonFocus={props.buttonFocus} isComplete={isNumberComplete(7, props.puzzle)}/>
          <Button value='8' doButtonClick={props.doButtonClick}
            buttonFocus={props.buttonFocus} isComplete={isNumberComplete(8, props.puzzle)}/>
          <Button value='9' doButtonClick={props.doButtonClick}
            buttonFocus={props.buttonFocus} isComplete={isNumberComplete(9, props.puzzle)}/>
        </Box>
      </Box>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    puzzle:state.puzzle,
    buttonFocus:state.control.buttonFocus,
    settings:state.settings
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    doButtonClick:(number) => dispatch(doButtonClick(number)),
    resetControls:() => dispatch(resetControls()),
    toggleThemeMode:() => dispatch(toggleThemeMode()),
    toggleNoises:() => dispatch(toggleNoises()),
  }
}

const ButtonsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Buttons)
 
export default ButtonsContainer