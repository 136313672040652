import React from "react";

import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import RegisterForm from './register-form'

const styles = theme => ({
  root: {
    height:'100%'
  },
  item: {
    paddingTop: theme.spacing(4),
  },
  cardHeader: {
    paddingBottom: theme.spacing(0.5),
  }
});

class RegisterComponent extends React.Component {
  constructor(){
    super();
    this.state ={
      target:'/home',
      show:true,
    }
  }
  componentDidMount() {
    if(this.props.account == null)
      this.props.getAccount(true)
        .then(account => {
          if(account != null &&
              !account.guest)
            this.props.history.push('/home')
        });
    else{

    }
  }
  onExited(){
    this.props.history.push(this.state.target)
  }
  beginExit(target){
    this.setState({
      target,
      show:!this.state.show
    });
  };
  onFormFocus(isIn){
    console.log(isIn)
  };
  render() {
    const { classes } = this.props;

    return(
      <Container className={classes.root} maxWidth="lg">
        <Grid className={classes.root} container>
          <Slide direction="left" onExited={this.onExited.bind(this)} in={this.state.show}  mountOnEnter unmountOnExit>
            <Grid item className={classes.item}>
              <Card>
                <CardHeader className={classes.cardHeader} title="Sutwoku" />
                <CardContent>
                  {this.props.account &&
                    <Typography variant="body2" gutterBottom>
                      Delighted to see you registering! Don't worry all your game stats will automatically be transfered to your new account.
                    </Typography>            
                  }
                  <Grid container justify="center" alignItems="center">
                    <RegisterForm register={this.props.createAccount} exit={this.beginExit.bind(this)}/>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Slide>
        </Grid>
      </Container>
    )
  }
}


RegisterComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RegisterComponent);