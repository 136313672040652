import {LogLevel} from '../logging/logger';

export class ContentCache {
  static constitute () { }
  constructor(logger, appConfig) {
    this.logger = logger;
    this.appConfig = appConfig;
  }
  init(){
    this.audio={
      wrongGuess:new Audio(`${this.appConfig.contentBaseUrl}audio/wrong_guess.mp3`),
      rightGuessYou:new Audio(`${this.appConfig.contentBaseUrl}audio/right_guess_you.mp3`),
      rightGuessThem:new Audio(`${this.appConfig.contentBaseUrl}audio/right_guess_them.mp3`)
    }
  }
  getAudio(){
    return this.audio;
  }
}