import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Square from './square';

const getAreaTiles = (tiles, colStart, rowStart) => {
  var area = [];
  if(tiles && tiles.length > 0)
  {
    for(var col = colStart;col < colStart + 3;col++){
      var currentRow = []
      for(var row = rowStart;row < rowStart + 3;row++){
        currentRow.push(tiles[col][row]);
      }
      area.push(currentRow);
    }
  }
  return area;
}

const useStyles = makeStyles((theme) => ({
  root:{
    height: 'calc(min(56vh, 100vw) - 32px)'
  }
}));

export default function Board(props) {
  const classes = useStyles();
  return (
    <Box className={classes.root} >
      <Box borderTop={0} borderRight={1} borderBottom={0} borderLeft={0}  width='33.33%' height="33.33%" display="inline-block">
        <Square tiles={getAreaTiles(props.puzzle, 0, 0)} doTileClick={props.doTileClick} />
      </Box>
      <Box borderTop={0} borderRight={1} borderBottom={0} borderLeft={0}  width='33.33%' height="33.33%" display="inline-block">
        <Square tiles={getAreaTiles(props.puzzle, 0, 3)} doTileClick={props.doTileClick} />
      </Box>
      <Box borderTop={0} borderRight={0} borderBottom={0} borderLeft={0}  width='33.33%' height="33.33%" display="inline-block">
        <Square tiles={getAreaTiles(props.puzzle, 0, 6)} doTileClick={props.doTileClick} />
      </Box>
      <Box borderTop={1} borderRight={1} borderBottom={0} borderLeft={0}  width='33.33%' height="33.33%" display="inline-block">
        <Square tiles={getAreaTiles(props.puzzle, 3, 0)} doTileClick={props.doTileClick} />
      </Box>
      <Box borderTop={1} borderRight={1} borderBottom={0} borderLeft={0}  width='33.33%' height="33.33%" display="inline-block">
        <Square tiles={getAreaTiles(props.puzzle, 3, 3)} doTileClick={props.doTileClick} />
      </Box>
      <Box borderTop={1} borderRight={0} borderBottom={0} borderLeft={0}  width='33.33%' height="33.33%" display="inline-block">
        <Square tiles={getAreaTiles(props.puzzle, 3, 6)} doTileClick={props.doTileClick} />
      </Box>
      <Box borderTop={1} borderRight={1} borderBottom={0} borderLeft={0}  width='33.33%' height="33.33%" display="inline-block">
        <Square tiles={getAreaTiles(props.puzzle, 6, 0)} doTileClick={props.doTileClick} />
      </Box>
      <Box borderTop={1} borderRight={1} borderBottom={0} borderLeft={0}  width='33.33%' height="33.33%" display="inline-block">
        <Square tiles={getAreaTiles(props.puzzle, 6, 3)} doTileClick={props.doTileClick} />
      </Box>
      <Box borderTop={1} borderRight={0} borderBottom={0} borderLeft={0}  width='33.33%' height="33.33%" display="inline-block">
        <Square tiles={getAreaTiles(props.puzzle, 6, 6)} doTileClick={props.doTileClick} />
      </Box>
    </Box>
  );
}