import {
  SET_SETTINGS, 
  UPDATE_SETTINGS} from './../actions/settings'

const control = (state, action) => {
  
  if(!state)
    state = {
      themeType:'dark',
      noises:true
    };

  switch(action.type){
    case SET_SETTINGS:
      return Object.assign({}, state, {
        themeType:action.settings.themeType ?? 'dark',
        noises:action.settings.noises === undefined ? true : action.settings.noises
      })
    case UPDATE_SETTINGS:
      return Object.assign({}, state, action.settings)
    default:
      return state;
  }
};

export default control;