import React from 'react';
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';

import {
  ButtonGroup,
  Button} from '@material-ui/core';

import {changeGameLevel} from './../../../actions/match-maker'

const useStyles = makeStyles((theme) => ({
  difficultyButtonGroup:{
    width: "100%",
  },
  difficultyButton:{
    width: "50%",
    padding:theme.spacing(0.25)
  },
  difficultyButtonActive:{
    width: "50%",
    padding:theme.spacing(0.25)
  }
}));

function GameLevel(props) {
  const classes = useStyles();
  const setDifficulty = (difficulty) => {
    props.changeGameLevel(difficulty);
  };
  return (
      <ButtonGroup 
        className={classes.difficultyButtonGroup}
        variant="contained" >
        <Button size="small" 
          variant={props.gameLevel == "EASY" ? "contained" : "outlined"}
          color={props.gameLevel == "EASY" ? "primary" : "default"}
          className={classes.difficultyButton}
          onClick={() => setDifficulty("EASY")}>
          Easy
        </Button>
        <Button size="small" 
          variant={props.gameLevel == "MEDIUM" ? "contained" : "outlined"}
          color={props.gameLevel == "MEDIUM" ? "primary" : "default"}
          className={classes.difficultyButton}
          onClick={() => setDifficulty("MEDIUM")}>
          Medium
        </Button>
        <Button size="small" 
          variant={props.gameLevel == "HARD" ? "contained" : "outlined"}
          color={props.gameLevel == "HARD" ? "primary" : "default"}
          className={classes.difficultyButton}
          onClick={() => setDifficulty("HARD")}>
          Hard
        </Button>
        {/* <Button size="small" 
            variant={props.gameLevel == "INSANE" ? "contained" : "outlined"}
            color={props.gameLevel== "INSANE" ? "primary" : "default"}
          className={classes.difficultyButton}
          onClick={() => setDifficulty("INSANE")}>
          Insane
        </Button> */}
      </ButtonGroup>
  );
}

const mapStateToProps = state => {
  return {
    gameLevel:state.matchMaker.gameLevel,
    isLoading:state.matchMaker.isLoading
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeGameLevel:(gameLevel) => dispatch(changeGameLevel(gameLevel)),
  }
}
 
const GameLevelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GameLevel)
 
export default GameLevelContainer