import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import {Card, CardHeader, CardContent, Avatar, Typography, Divider, Box} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    padding:theme.spacing(1),
    '&:last-child': {
      paddingBottom:theme.spacing(1),
    }
  },
  header:{
    padding:theme.spacing(1),
  },
  player: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
  },
  opponent: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: theme.palette.secondary.main,
  }
}));

export default function Player(props) {
  const classes = useStyles();
  var details = props.details ?? {};
  var playerTheme = props.player ? classes.player : classes.opponent;

  return (
    <Card className={classes.card}>
      <CardHeader className={classes.header} avatar={
          <Avatar aria-label="recipe" className={playerTheme}>
            {details.name ? details.name.charAt(0) : '?'}
          </Avatar>
        } title={details.name ? details.name : 'Loading...'}
          subheader={details.ranking ? details.ranking + details.rankingChange + ' (' + details.rankingChange  +')' : 'Loading...'}
          subheaderTypographyProps={{variant:'caption' }} />
    </Card>
  );
}