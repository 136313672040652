import React from "react";
import ReactDOM from "react-dom";

import { Provider } from 'react-redux'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { HashRouter as Router, Route } from 'react-router-dom'

import {createServices} from './ioc'

import GuestContainer from "./containers/account/guest";
import RegisterContainer from "./containers/account/register";
import SignInContainer from "./containers/account/sign-in";
import HomeContainer from "./containers/home";
import GameContainer from "./containers/game";
import AppContainer from "./containers/app";
import GameOverComponent from "./containers/gameover";
import MatchMakerComponent from "./containers/match-maker";


import account from './reducers/account';
import control from './reducers/control';
import game from './reducers/game';
import puzzle from './reducers/puzzle';
import settings from './reducers/settings';
import stats from './reducers/stats';
import gameover from './reducers/gameover';
import matchMaker from './reducers/match-maker';
import modals from './reducers/modals';

const store = createStore(
  combineReducers({
    account,
    control,
    game,
    puzzle,
    settings,
    stats,
    gameover,
    matchMaker,
    modals,
  }), 
  composeWithDevTools(
    applyMiddleware(thunk.withExtraArgument(createServices()))
  )
);

ReactDOM.render(
  <Provider store={store}>
     <AppContainer>
      <Router>
        <Route exact path="/" component={GuestContainer} />
        <Route path="/account/register" component={RegisterContainer} />
        <Route path="/account/signin" component={SignInContainer} />
        <Route path="/home" component={HomeContainer} />
        <Route path="/match-maker" component={MatchMakerComponent} />
        <Route path="/game" component={GameContainer} />
        <Route path="/gameover" component={GameOverComponent} />
      </Router>
    </AppContainer>
  </Provider>,
  document.querySelector('#app'),
);