import React from "react";

import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backdropFilter: 'blur(4px)'
  },
  paper:{
    width:'60%'
  },
  field:{
    width:'100%'
  },
  button:{
    width:'100%'
  }
}));

export default function Friend(props) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={props.show}>
        <Paper className={classes.paper} >
          <Formik
            className={classes.form}
            initialValues={{
              username: '',
              password: '',
            }}
            validate={values => {
              const errors = {};
              if (!values.username) {
                errors.username = 'Required';
              } else if (values.username.length < 3) {
                errors.username = 'Minimum username length is 3 characters';
              } else if (values.username.length > 12) {
                errors.username = 'Maximum username length is 12 characters';
              }

              return errors;
            }}
            onSubmit={(values, {setSubmitting, setErrors}) => {
              props.onSubmit(values.username);
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <Box textAlign='center' m={2}>
                  <Typography component="div" variant="subtitle2">
                    Who would you like to play against?
                  </Typography> 
                </Box>
                <Box m={2}>
                  <Field className={classes.field} component={TextField} name="username" type="string" 
                        label="Username" variant="outlined" size="small" autoFocus autoCapitalize="words"/>
                </Box>
                <Box m={2}>
                  <Button variant="contained" color="primary" size="small"
                      disabled={isSubmitting} onClick={submitForm} className={classes.button}>
                    Play
                  </Button>
                </Box>
                <Box m={2}>
                  <Button variant="contained" color="secondary" size="small"
                      disabled={isSubmitting} onClick={() => props.onBack('/home')} className={classes.button}>
                    Back to home
                  </Button>
                </Box>
              </Form>
            )}
          </Formik> 
      </Paper>
    </Backdrop>
  );
}