import {SET_MATCH_MAKER_STATUS, 
  FRIEND_WAITING, 
  REJECT_FRIEND_WAITING,
  ACCEPT_FRIEND_GAME,
  GAME_FOUND,
  INIT_STATE,
  LEFT_QUEUE,
  FRIEND_REJECTED_GAME,
  DISMISS_FRIEND_REJECTED_GAME,
  SET_GAME_LEVEL,
  SET_FRIEND_GAME_STATS} from './../actions/match-maker'

const defaultState = {
  status:'INIT',
  gameLevel:"EASY",
};

const isLoading = (status) => {
  return status === 'WAITING_FOR_FRIEND' ||
        status === 'WAITING_FOR_OPPONENT';
}

const matchMaker = (state, action) => {
  if(!state)
    state = defaultState;
    
  switch(action.type){
    case INIT_STATE:
      var initState = defaultState;
      if(state &&
          state.gameLevel &&
          state.gameLevel !== '')
        initState.gameLevel = state.gameLevel
      return initState;
    case SET_GAME_LEVEL:
      return Object.assign({}, state, {gameLevel:action.gameLevel})
    case SET_MATCH_MAKER_STATUS:
      return Object.assign({}, state, {status:action.status, isLoading:isLoading(action.status)})
    case FRIEND_WAITING:
        return Object.assign({}, state, {status:'A_FRIEND_WANTS_TO_PLAY',friendWaiting:action.friendName, showFriendWaiting:true, isLoading:true})
    case REJECT_FRIEND_WAITING:
      return Object.assign({}, state, {showFriendWaiting:false, isLoading:false, status:'WAITING'})
    case ACCEPT_FRIEND_GAME:
      return Object.assign({}, state, {showFriendWaiting:false, isLoading:true})
    case GAME_FOUND:
      return Object.assign({}, state, {status:GAME_FOUND, gameRef: action.gameRef, gameType:action.gameType, isLoading:true})
    case LEFT_QUEUE:
      return Object.assign({}, state, {status:'WAITING', isLoading:false})
    case FRIEND_REJECTED_GAME:
        return Object.assign({}, state, {showFriendRejectedGame:true, isLoading:false, rejectedFriendName:action.friendName, status:'WAITING'})
    case DISMISS_FRIEND_REJECTED_GAME:
        return Object.assign({}, state, {showFriendRejectedGame:false, isLoading:false})
    case SET_FRIEND_GAME_STATS:
        return Object.assign({}, state, {friendGameStats:action.stats})
    default:
      return state;
  }
 };

 export default matchMaker;