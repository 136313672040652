import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Box,
  CircularProgress,
  Typography,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card:{
    padding:theme.spacing(1)
  }
}));

export default function Percentile(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Grid container>
      <Grid item xs={12}>
          <Typography component="div" variant="caption">
              <Box textAlign="center">
              PERCENTILE
              </Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="div" variant="h4">
            <Box textAlign="center" fontWeight="fontWeightBold">
            {props.stats &&
              props.stats.percentile + '%'
            }
            {(!props.stats) &&
              <CircularProgress size='0.875rem' color='inherit'/>
            }
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}