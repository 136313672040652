import React from "react";
import AppConfig from "./../../config";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import {Container, 
  Slide,
  Grid,
  Fab,
  ButtonGroup,
  Button
} from '@material-ui/core';
  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faPlay
} from '@fortawesome/free-solid-svg-icons'

import Rating from './stats/rating'
import Account from './account'
import GameRecord from './stats/game-record'
import SutwokuMenuContainer from './menu/sutwoku-menu.js'
import Percentile from './stats/percentile'
import AverageScore from './stats/average-score'
import About from './about/about'
import RatingRequestContainer from './modals/rating-request'
import StoreLinksContainer from './modals/store-links'

const styles = theme => ({
  root: {
    height:'100%',
    position: 'relative'
  },
  grid: {
    height:'100%',
  },
  avatar:{
    backgroundColor: theme.palette.primary.main
  },
  fab:{
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 20,
      left: 'auto',
      position: 'absolute'
  },
  difficultyButtonGroup:{
    width: "100%",
  },
  difficultyButton:{
    width: "50%",
    padding:theme.spacing(0.25)
  },
  difficultyButtonActive:{
    width: "50%",
    padding:theme.spacing(0.25)
  },
  fabExtendedIcon: {
    marginRight: theme.spacing(1),
  }
});

class HomeComponent extends React.Component {
  constructor(){
    super();
    this.state ={
      show:true,
      avatarClickCount:0,
      isAboutOpen:false
    }
  }
  componentDidMount(){
    this.props.cleanUpGame();
    this.props.loadSettings();
    this.props.getAccount(true)
      .then((account) => {
        this.props.getStats(account);
        this.props.loadModalSettings(); 
      })
  }
  onExited(){
    this.props.history.push(this.state.target)
  }
  beginExit(target){
    this.setState({
      target,
      show:!this.state.show
    });
  };
  beginExit(target){
    this.setState({
      target,
      show:!this.state.show
    });
  };
  changeDifficulty(difficulty){
    this.props.changeDifficulty(difficulty)
    this.props.getStats();
  }
  render() {
    const { classes } = this.props;
    return(
      <Slide direction="left" className={classes.root} onExited={this.onExited.bind(this)} in={this.state.show} mountOnEnter unmountOnExit>
        <Container id='home-container' maxWidth="lg" position='relative'>
          <SutwokuMenuContainer 
          className={classes.root} 
          className={classes.menu} 
          beginExit={this.beginExit.bind(this)} 
          openAbout={() => this.setState({isAboutOpen:true})}/>
          <Grid container className={classes.grid} alignItems="center">
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Account account={this.props.account} unregister={this.props.unregister} />
                </Grid>
                <Grid item xs={12}>
                  <ButtonGroup 
                    className={classes.difficultyButtonGroup}
                    variant="contained" >
                    <Button size="small" 
                      variant={this.props.stats.difficulty == "EASY" ? "contained" : "outlined"}
                      color={this.props.stats.difficulty == "EASY" ? "primary" : "default"}
                      className={classes.difficultyButton}
                      onClick={() => this.changeDifficulty("EASY")}>
                      Easy
                    </Button>
                    <Button size="small" 
                      variant={this.props.stats.difficulty == "MEDIUM" ? "contained" : "outlined"}
                      color={this.props.stats.difficulty == "MEDIUM" ? "primary" : "default"}
                      className={classes.difficultyButton}
                      onClick={() => this.changeDifficulty("MEDIUM")}>
                      Medium
                    </Button>
                    <Button size="small" 
                      variant={this.props.stats.difficulty == "HARD" ? "contained" : "outlined"}
                      color={this.props.stats.difficulty == "HARD" ? "primary" : "default"}
                      className={classes.difficultyButton}
                      onClick={() => this.changeDifficulty("HARD")}>
                      Hard
                    </Button>
                    {/* <Button size="small" 
                       variant={this.props.stats.difficulty == "INSANE" ? "contained" : "outlined"}
                       color={this.props.stats.difficulty == "INSANE" ? "primary" : "default"}
                      className={classes.difficultyButton}
                      onClick={() => this.setDifficulty("INSANE")}>
                      Insane
                    </Button> */}
                  </ButtonGroup>
                </Grid>
                <Grid item xs={12}>
                  <Rating stats={this.props.stats}/>
                </Grid>
                <Grid item xs={12}>
                  <GameRecord stats={this.props.stats.gameStats}/>
                </Grid>
                <Grid item xs={6}>
                  <Percentile stats={this.props.stats.rankingCountStats}/>
                </Grid>
                <Grid item xs={6}>
                  <AverageScore stats={this.props.stats.gameStats}/>
                </Grid>
              </Grid>
              <About isOpen={this.state.isAboutOpen} close={() => {this.setState({isAboutOpen:false})}}/>
              <RatingRequestContainer />
              <StoreLinksContainer />
            </Grid>
            <Fab variant="extended" className={classes.fab} color="primary" onClick={() => this.beginExit('/match-maker')}>
              <FontAwesomeIcon icon={faPlay} size="1x" className={classes.fabExtendedIcon} />
              New Game
            </Fab>
          </Grid>
        </Container>
      </Slide>
    )
  }
}

HomeComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeComponent);