import React from 'react';
import { connect } from 'react-redux'

import AppConfig from './../../../config'

import {
  Backdrop,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import {setMobileModalShown} from './../../../actions/modals'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    maxWidth:"80%"
  },
  text:{
    margin:theme.spacing(1)
  }
}));

function StoreLinks(props) {
  const classes = useStyles();

  const handleClose = () => {
    props.setMobileModalShown();
  };

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPhone/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      return "unknown";
  }

  const getStoreName = () => {
    var deviceType = getMobileOperatingSystem();

    if(deviceType == 'Android')
      return 'Play Store'
    else if (deviceType == 'iOS')
      return 'App Store'
  }

  const getLink = () => {
    var deviceType = getMobileOperatingSystem();

    if(deviceType == 'Android')
      return 'market://details?id=com.vcvi.folkwhosudoku'
    else if (deviceType == 'iOS')
      return 'https://apps.apple.com/app/id1517689580'
    else 
      return 'https://www.sutwoku.com/'
  }

  return (
    <Backdrop
      className={classes.backdrop}
      open={props.shouldShow}
      onClick={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
        <Card className={classes.card}>
        <CardContent>
          <Typography variant="h4" className={classes.text}>
            Welcome to Sutwoku
          </Typography>
          <Typography variant="body1" color="textSecondary" className={classes.text}>
            I am very excited for you to jump right in and start playing with friens or rising the Sutwoku rankings!
          </Typography>
          <Typography variant="body1" color="textSecondary" className={classes.text}>
            {`It looks like you are on a mobile device. You can continue to web version of Sutwoku, however we suggest you download our mobile app from the ${getStoreName()} for a much better experience!`}
          </Typography>
          </CardContent>
          <CardActions>
          <Button size="small" color="primary" variant="contained" href={getLink()}>
            Go to the Store
          </Button>
          <Button size="small" color="secondary" onClick={handleClose}>
            Continue
          </Button>
          </CardActions>
        </Card>
    </Backdrop>
  );
}

function shouldShowLinks(modalsState) {
  if(AppConfig.isWebVersion && !modalsState.mobileModalShown){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      return true;
    }
  }
  return false;
}

const mapStateToProps = (state, ownProps) => {
  return {
    shouldShow: shouldShowLinks(state.modals),
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setMobileModalShown:() => dispatch(setMobileModalShown())
  }
}
 
const StoreLinksContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreLinks)
 
export default StoreLinksContainer

