import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import './../../../../node_modules/animate.css/animate.css'

const useStyles = makeStyles((theme) => ({
  paper:{
    height:'100%',
    borderWidth:2,
  },
  content:{
    height:'100%',
    width:'100%', 
    display: 'flex'
  }, 
  text:{  
    margin: 'auto',
    cursor: 'default'
  },
  player1:{
    borderWidth:2,
    borderColor: theme.palette.primary.main
  },
  player2:{
    borderWidth:2,
    borderColor: theme.palette.secondary.main
  },
  focus:{
    borderWidth:2,
    borderColor: theme.palette.primary.light,
    color: theme.palette.background.paper
  },
  hide:{
    color: theme.palette.background.paper
  }
}));

export default function Tile(props) {
  const classes = useStyles();

  var style = {};

  if(props.tile.value == 0){
    if(props.tile.style === 'focus')
      style = classes.focus + ' animate__animated animate__pulse animate__faster';
    else
      style = classes.hide;
  }
  else if(props.tile.style === 'player-1')
    style = classes.player1 + ' animate__animated animate__flip animate__faster';
  else if(props.tile.style === 'player-2')
    style = classes.player2 + ' animate__animated animate__flash animate__faster';
 
  return (
    <Box width='27.33%' height="27.33%" p='0' m='3%' display="inline-block">
      <Paper className={`${classes.paper} ${style}`} onClick={() => props.doTileClick(props.tile.col, props.tile.row)} variant="outlined">
        <Typography className={classes.content} component="div">
          <Box fontWeight="fontWeightMedium" className={classes.text}>
            {props.tile.value}
          </Box>
        </Typography>
      </Paper>
    </Box>  
  );
}