import { connect } from 'react-redux'
import RegisterComponent from '../../components/account/register/register'

import {createAccount, getAccount} from '../../actions/account'


const mapStateToProps = state => {
  return {
    account:state.account
  }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createAccount:(account) => dispatch(createAccount(account)),
    getAccount:() => dispatch(getAccount())
  }
}
 
const RegisterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterComponent)
 
export default RegisterContainer